import React, { Fragment, useState, useEffect } from "react";
import { Button, Skeleton, TextField } from "@mui/material";
import {
  StyledAddressChange,
  StyledMob,
  StyledPickup,
  StyledShipping,
  StyledWeb,
} from "./styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { useForm } from "react-hook-form";
import {
  addAddress,
  addShipmentMethod,
  getAddress,
} from "features/address/addresSlice";
import { ReactComponent as ErrorIcon } from "assets/error.svg";
import { toast } from "react-toastify";
import { addEmail, addPhone, getEmail } from "features/user/userSlice";

export const PickupAddress = ({
  setAddressChange,
  edit,
  updatedAddress,
  disabled,
  rmsConfig,
  serviceable,
}) => {
  return (
    <StyledPickup>
      <div className="pickup">
        {!disabled ? "Pickup & Delivery Address" : "Delivery Address"}
        {edit && (
          <p
            className="change"
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => setAddressChange(true)}
          >
            {" "}
            Change
          </p>
        )}
      </div>
      <p className="pickup-address">
        {updatedAddress && updatedAddress.addressLine1},{" "}
        {updatedAddress && updatedAddress.addressLine2}{" "}
        {updatedAddress && updatedAddress.addressLine2 && ","}{" "}
        {updatedAddress && updatedAddress.city} -{" "}
        {updatedAddress && updatedAddress.pincode},{" "}
        {updatedAddress && updatedAddress.state}
      </p>
      {(!rmsConfig.supports_return_pickup && rmsConfig.self_ship) ||
        (!serviceable) && (
          <div>
            <p className="ship-back"> {!serviceable && "Your pincode is not serviceable."} Ship your products back to</p>
            <p className="delivery-address">
              {rmsConfig.return_address.name},{" "}
              {rmsConfig.return_address.address_line_1},{" "}
              {rmsConfig.return_address.address_line_2}{" "}
              {rmsConfig.return_address.address_line_2 && ","}{" "}
              {rmsConfig.return_address.city}, {rmsConfig.return_address.state},{" "}
              {rmsConfig.return_address.country}, -{" "}
              {rmsConfig.return_address.pin}
            </p>
          </div>
        )}
    </StyledPickup>
  );
};

function Address({
  allOrders,
  selectedOrder,
  addressLoader,
  serviceableCheck,
  setServiceableCheck,
  serviceability,
  checkServiceabilty,
  pincode,
  setPincode,
  serviceableLoader,
  setServiceableLoader,
  addressApiLoader,
  tempServiceablity,
  setTempServiceabilty,
  deliveryStatus,
}) {
  const [addressChange, setAddressChange] = useState(false);

  const selectedItem = allOrders.filter(
    (a) => a.order_number === selectedOrder
  );
  const merchantConfig = useSelector(getMerchantConfig);
  const rmsConfig = useSelector(getRmsConfig);

  const [state, setState] = useState(selectedItem[0].shipping_address.province);
  const [city, setCity] = useState(selectedItem[0].shipping_address.city);
  const [addressLine1, setAddressLine1] = useState(
    selectedItem[0].shipping_address.address1
  );
  const [addressLine2, setAddressLine2] = useState(
    selectedItem[0].shipping_address.address2
  );
  const [firstName, setFirstName] = useState(
    selectedItem[0].customer_first_name
  );
  const [lastName, setLastName] = useState(selectedItem[0].customer_last_name);
  const [wrongAddress, setWrongAddress] = useState(false);
  const [userInfoError, setUserInfoError] = useState(false);
  const updatedAddress = useSelector(getAddress);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");
  const { handleSubmit } = useForm();
  toast.configure();
  const format = /[\!\@\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\;\|\~\`\_\?]/;

  const missingEmail = useSelector(getEmail);

  const [userPhoneNumber, setUserPhoneNumber] = useState(
    selectedItem[0].user_phone_number
  );
  const [userEmail, setUserEmail] = useState(
    missingEmail || selectedItem[0].user_email
  );

  const validateAddress = () => {
    const isValidAddressLine2 = addressLine2 ?? "";
    const totalLength = (addressLine1 + isValidAddressLine2).length;
    return (
      format.test(pincode) ||
      format.test(addressLine1) ||
      format.test(addressLine2) ||
      format.test(state) ||
      format.test(city) ||
      addressLine1.length < 15 ||
      addressLine1.length > 80 ||
      (isValidAddressLine2 !== "" && isValidAddressLine2.length < 10 && isValidAddressLine2.length > 70) ||
      totalLength < 150
    );
  };

  const validateUpdatedAddress = (address) => {
    const isValidAddressLine2 = address.addressLine2 ?? "";
    const totalLength = (address.addressLine1 + isValidAddressLine2).length;
    return (
      format.test(address.pincode) ||
      format.test(address.addressLine1) ||
      format.test(address.addressLine2) ||
      format.test(address.state) ||
      format.test(address.city) ||
      address.addressLine1.length < 15 ||
      address.addressLine1.length > 80 ||
      (isValidAddressLine2 !== "" && isValidAddressLine2.length < 10 && isValidAddressLine2.length > 70) ||
      totalLength > 150
    );
  };
  useEffect(() => {
    if (selectedItem !== null) {
      setPincode(
        selectedItem[0].shipping_address.zip ||
          selectedItem[0].billing_address.zip
      );
      if (validateUpdatedAddress(updatedAddress)) {
        setAddressChange(true);
        setWrongAddress(true);
      } else if (
        !(
          userEmail !== undefined &&
          userPhoneNumber !== undefined &&
          userEmail !== null &&
          userPhoneNumber !== null &&
          userEmail !== "" &&
          userPhoneNumber !== "" &&
          firstName &&
          lastName &&
          firstName !== null &&
          lastName !== null &&
          firstName !== "" &&
          lastName !== ""
        )
      ) {
        setAddressChange(true);
        setUserInfoError(true);
      }
    } else {
      setWrongAddress(false);
      setAddressChange(false);
      setUserInfoError(false);
    }
  }, [selectedItem[0], updatedAddress]);

  const addressHandler = () => {
    if (!validateAddress()) {
      setAddressChange(false);
      setServiceableCheck(tempServiceablity);
      dispatch(
        addAddress({
          pincode,
          firstName,
          lastName,
          addressLine1,
          addressLine2,
          state,
          city,
        })
      );
      dispatch(addPhone(userPhoneNumber));
      dispatch(addEmail(userEmail));
      setWrongAddress(false);
      setUserInfoError(false);
    } else {
      toast.error("No special characters allowed in address details", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };

  useEffect(() => {
    setTempServiceabilty(serviceableCheck);
  }, [serviceableCheck]);

  const shipmentHandler = (shipment) => {
    dispatch(addShipmentMethod(shipment));
    dispatch(
      addAddress({
        pincode,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        state,
        city,
      })
    );
  };

  const ShippingPreference = () => {
    return (
      <StyledShipping>
        {serviceableCheck || rmsConfig.self_ship ? (
          <Fragment>
            {serviceableCheck ? (
              <Fragment>
                <div style={{ textAlign: "center" }}>
                  <p style={{ color: "#333333B2" }}>
                    Your PIN Code ({pincode}) is eligibile for pickup and
                    delivery
                  </p>

                  <div style={{ marginTop: "20px" }}>
                    <p>
                      Your pickup and delivery address{" "}
                      <span
                        style={{
                          color: "#1d8dcc",
                          textDecoration: "underline",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setAddressChange(true)}
                      >
                        Change
                      </span>
                    </p>
                    <p
                      className="address"
                      style={{ color: "#333333B2", marginTop: "5px" }}
                    >
                      {updatedAddress && updatedAddress.addressLine1},{" "}
                      {updatedAddress && updatedAddress.addressLine2}{" "}
                      {updatedAddress && updatedAddress.addressLine2 && ","}{" "}
                      {updatedAddress && updatedAddress.city} -{" "}
                      {updatedAddress && updatedAddress.pincode},{" "}
                      {updatedAddress && updatedAddress.state}
                    </p>
                  </div>
                </div>
              </Fragment>
            ) : (
              rmsConfig.self_ship && (
                <Fragment>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ color: "#333333B2", lineHeight: "25px" }}>
                      Your PIN Code ({pincode}) is not eligibile for pickup.{" "}
                      <span
                        style={{
                          color: "#1d8dcc",
                          textDecoration: "underline",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setAddressChange(true)}
                      >
                        Change
                      </span>
                    </p>

                    <div
                      style={{
                        background: "#3333331A",
                        borderRadius: "10px",
                        padding: "25px",
                        marginTop: "20px",
                      }}
                    >
                      <p style={{ color: "#333333", fontWeight: "500" }}>
                        Please ship products back to company's warehouse.
                      </p>
                      <p
                        style={{
                          color: "#333333",
                          marginTop: "10px",
                          fontSize: "12px",
                        }}
                      >
                        ( Address will be sent to you on +91-
                        {selectedItem[0].user_phone_number} )
                      </p>
                    </div>
                  </div>
                </Fragment>
              )
            )}
          </Fragment>
        ) : (
          <p
            style={{
              color: "#333333B2",
              lineHeight: "1.75",
              width: "100%",
            }}
          >
            Sorry :( We cannot arrange for a return pickup as pincode is not
            serviceable.
          </p>
        )}
      </StyledShipping>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      {addressLoader ? (
        <div className="address-skeleton">
          <Skeleton variant="text" height={50} />
          <Skeleton variant="text" height={50} />
          <Skeleton variant="text" height={50} />
          <Skeleton variant="text" height={50} />
        </div>
      ) : deliveryStatus ? (
        <Fragment>
          <StyledWeb>
            {addressApiLoader ? (
              <div
                style={{
                  margin: "100px 0px",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <ErrorIcon />
                <p
                  style={{
                    marginTop: "25px",
                    color: "#1d8dcc",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                  onClick={() => window.location.reload()}
                >
                  Reload
                </p>
              </div>
            ) : (
              <ShippingPreference />
            )}
            <Button
              variant="contained"
              color="primary"
              style={{
                fontWeight: "500",
                textTransform: "capitalize",
              }}
              disabled={userInfoError || wrongAddress}
              onClick={() => {
                shipmentHandler(
                  serviceableCheck
                    ? "merchant_handles_return_shipment"
                    : "customer_ships_back"
                );
                let url = "/products";
                if (encipherencode !== null && encipherencode !== undefined) {
                  url += `?encipherencode=${encipherencode}`;
                }
                navigate(url);
              }}
            >
              Continue
            </Button>
            <p
              style={{
                color: "#33333355",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              *Charges may apply
            </p>
          </StyledWeb>

          {/*  Address Change */}

          {addressChange && (
            <StyledAddressChange serviceableCheck={serviceableCheck}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                  height: "80px",
                  padding: "32px",
                }}
              >
                <p
                  style={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                >
                  Your Pickup & Delivery Address
                </p>
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!format.test()) {
                      toast.error("Please fill all the fields", {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 6000,
                        hideProgressBar: false,
                        closeOnClick: true,
                      });
                    } else if (
                      (!validateAddress() && wrongAddress) ||
                      !wrongAddress
                    ) {
                      setAddressChange(false);
                      dispatch(
                        addAddress({
                          pincode: updatedAddress.pincode,
                          firstName: updatedAddress.customer_first_name,
                          lastName: updatedAddress.customer_last_name,
                          addressLine1: updatedAddress.addressLine1,
                          addressLine2: updatedAddress.addressLine2,
                          state: updatedAddress.state,
                          city: updatedAddress.city,
                        })
                      );
                      dispatch(addPhone(userPhoneNumber));
                      dispatch(addEmail(userEmail));
                      setPincode(updatedAddress.pincode);
                      setTempServiceabilty(serviceableCheck);
                    } else {
                      toast.error(
                        "Please fill all the fields without special characters ",
                        {
                          position: toast.POSITION.TOP_RIGHT,
                          autoClose: 6000,
                          hideProgressBar: false,
                          closeOnClick: true,
                        }
                      );
                    }
                  }}
                >
                  {" "}
                  <CloseIcon
                    style={{ width: "24px", height: "24px", stroke: "#333" }}
                  />{" "}
                </p>
              </div>
              {wrongAddress && (
                <div style={{ padding: "20px 24px 0px" }}>
                  <p
                    style={{
                      color: "red",
                      padding: "10px 15px",
                      border: "1px solid red",
                      background: "#f4b3b3",
                      marginBottom: "0px",
                      borderRadius: "4px",
                    }}
                  >
                    Please fill all the fields without any special characters
                    (*, /, & , - , _)
                  </p>
                </div>
              )}
              {userInfoError && (
                <div style={{ padding: "20px 24px 0px" }}>
                  <p
                    style={{
                      color: "red",
                      padding: "10px 15px",
                      border: "1px solid red",
                      background: "#f4b3b3",
                      marginBottom: "0px",
                      borderRadius: "4px",
                    }}
                  >
                    Please fill all the fields
                  </p>
                </div>
              )}
              <form
                onSubmit={handleSubmit(addressHandler)}
                style={{ margin: "20px 0px", padding: "0px 32px" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    overflowY: "auto",
                  }}
                >
                  <TextField
                    name="pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    variant="outlined"
                    label="PIN Code"
                    defaultValue={
                      selectedItem[0].shipping_address.zip ||
                      selectedItem[0].billing_address.zip
                    }
                    inputProps={{ type: "text" }}
                    style={{ marginTop: "20px" }}
                  />
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#111",
                      marginLeft: "20px",
                      cursor: "pointer",
                      marginTop: "6px",
                    }}
                    onClick={() => {
                      setServiceableLoader(true);
                      checkServiceabilty(pincode, true);
                      setAddressLine1(null);
                      setAddressLine2(null);
                      setState(null);
                      setCity(null);
                    }}
                  >
                    Check serviceability
                  </p>
                </div>

                {serviceableLoader ? (
                  <div style={{ marginTop: "40px" }}>
                    <Skeleton height={75} />
                    <Skeleton height={75} />
                    <Skeleton height={75} />
                  </div>
                ) : (
                  serviceability && (
                    <Fragment>
                      {tempServiceablity ? (
                        <p
                          style={{
                            color: "#333333B2",
                            lineHeight: "1.75",
                            width: "100%",
                            margin: "20px 0px",
                            fontSize: "14px",
                          }}
                        >
                          {" "}
                          Yayy ! Your PIN Code is eligible for pickup and
                          delivery. <br />
                        </p>
                      ) : (
                        <p
                          style={{
                            color: "#333333",
                            lineHeight: "1.75",
                            width: "100%",
                            margin: "20px 0px",
                            fontSize: "14px",
                          }}
                        >
                          Your PIN Code is ineligible for pickup of products.{" "}
                          <br />
                          Please provide address for delivery.{" "}
                        </p>
                      )}
                      <div style={{ display: "flex" }}>
                        <TextField
                          name="state"
                          variant="outlined"
                          value={state}
                          onChange={(e) => setState(e.target.value)}
                          label="State"
                          required
                          fullWidth
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px" }}
                        />
                        <TextField
                          name="city"
                          variant="outlined"
                          onChange={(e) => setCity(e.target.value)}
                          label="City"
                          value={city}
                          required
                          fullWidth
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px", marginLeft: "10px" }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          name="first_name"
                          variant="outlined"
                          onChange={(e) => setFirstName(e.target.value)}
                          label="First Name"
                          value={firstName}
                          required
                          fullWidth
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px" }}
                        />
                        <TextField
                          name="last_name"
                          variant="outlined"
                          onChange={(e) => setLastName(e.target.value)}
                          label="Last Name"
                          value={lastName}
                          required
                          fullWidth
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px", marginLeft: "10px" }}
                        />
                      </div>
                      <div style={{ display: "flex" }}>
                        <TextField
                          name="email"
                          variant="outlined"
                          value={userEmail}
                          onChange={(e) => {
                            setUserEmail(e.target.value);
                          }}
                          label="Email"
                          required
                          fullWidth
                          inputProps={{ type: "email" }}
                          style={{ marginTop: "20px" }}
                        />
                        <TextField
                          name="phone_number"
                          variant="outlined"
                          value={userPhoneNumber}
                          disabled={
                            userPhoneNumber &&
                            userPhoneNumber !== null &&
                            userPhoneNumber.length > 9
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setUserPhoneNumber(e.target.value);
                          }}
                          label="Phone Number"
                          required
                          fullWidth
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px", marginLeft: "10px" }}
                        />
                      </div>
                      <TextField
                        name="address_line1"
                        variant="outlined"
                        onChange={(e) => setAddressLine1(e.target.value)}
                        label="Address Line 1"
                        value={addressLine1}
                        required
                        fullWidth
                        defaultValue=""
                        inputProps={{ type: "text" }}
                        style={{ marginTop: "20px" }}
                      />
                      <TextField
                        name="address_line2"
                        variant="outlined"
                        onChange={(e) => setAddressLine2(e.target.value)}
                        label="Address Line 2"
                        value={addressLine2}
                        fullWidth
                        defaultValue=""
                        inputProps={{ type: "text" }}
                        style={{ marginTop: "20px" }}
                      />

                      <Button
                        color="primary"
                        type="submit"
                        style={{
                          padding: "1rem",
                          borderRadius: !merchantConfig.use_rounded_edge_buttons
                            ? "0px"
                            : "4px",
                          margin: "40px auto",
                          display: "block",
                          border: `1px solid ${merchantConfig.primary_theme_color}80`,
                          cursor: "pointer",
                          textTransform: "capitalize",
                        }}
                      >
                        {userInfoError ? "Save & Continue" : "Save Address"}
                      </Button>
                    </Fragment>
                  )
                )}
              </form>
            </StyledAddressChange>
          )}

          <StyledMob>
            <div style={{ padding: "0px 15px", marginTop: "20px" }}>
              {addressApiLoader ? (
                <div
                  style={{
                    margin: "80px 0px",
                    textAlign: "center",
                    dispaly: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <ErrorIcon />
                  <p
                    style={{
                      marginTop: "25px",
                      color: "#1d8dcc",
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontSize: "20px",
                    }}
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </p>
                </div>
              ) : (
                <ShippingPreference />
              )}
            </div>
            <Button
              variant="contained"
              color="primary"
              style={{
                margin: "10px auto",
                display: "block",
                fontWeight: "500",
                textTransform: "capitalize",
              }}
              disabled={userInfoError || wrongAddress}
              onClick={() => {
                shipmentHandler(
                  serviceableCheck
                    ? "merchant_handles_return_shipment"
                    : "customer_ships_back"
                );
                let url = "/products";
                if (encipherencode !== null && encipherencode !== undefined) {
                  url += `?encipherencode=${encipherencode}`;
                }
                navigate(url);
              }}
            >
              Continue
            </Button>
            <p
              style={{
                color: "#33333355",
                fontSize: "12px",
                marginTop: "10px",
              }}
            >
              *Charges may apply
            </p>
          </StyledMob>
        </Fragment>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "80vh",
            justifyContent: "center",
            fontSize: "20px",
            color: "#333",
            padding: "0px 20px",
          }}
        >
          <p style={{ textAlign: "center", lineHeight: "1.25" }}>
            Looks like your order is not delivered yet.
          </p>
          <p
            style={{
              marginTop: "10px",
              textAlign: "center",
              lineHeight: "1.25",
            }}
          >
            Please try to initiate a exchange/return request after the order is
            delivered!
          </p>
        </div>
      )}
    </div>
  );
}

export default Address;

import styled from "styled-components";

export const StyledApp = styled.div`
  font-family: ${(props) =>
    props.merchantConfig.google_font_family !== null
      ? props.merchantConfig.google_font_family
      : "Poppins,sans-serif"};

  .step-label {
    font-size: 12px;
    color: #333333;
    font-weight: 500;
  }

  .return-id {
    color: #333333;
    font-size: 14px;
    font-weight: 500;
    flex-shrink: 0;
  }

  .request-date {
    font-size: 10px;
    color: #333333b2;
  }

  @media (min-width: 800px) {
    font-size: 14px;

    .return-id {
      font-size: 1.25rem;
    }

    .request-date {
      font-size: 14px;
    }
  }

  @media (min-width: 600px) {
    .step-content {
      display: flex;
      justify-content: center;
      margin-left: -10px;
      border-left: none;
    }
  }
`;

export const StyledComments = styled.div`
  position: fixed;
  bottom: 0;
  right: 1rem;
  z-index: 999;

  .comments-header {
    display: flex;
    align-items: center;
    padding: 10px;
    min-width: 300px;
    background: #fff;
    cursor: pointer;
  }

  .commented-merchant {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    float: right;
  }

  .commented-customer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    float: left;
  }

  .comment-merchant {
    background: #1d8dcc33;
    border: 1px solid #1d8dcc;
    padding: 12px;
    border-radius: 1px 14px 14px 14px;
    max-width: 90%;
    margin-top: 1rem;
  }

  .comment-customer {
    background: #fff;
    border: 1px solid #1d8dcc;
    text-align: right;
    padding: 12px;
    border-radius: 14px 1px 14px 14px;
    max-width: 90%;
    margin-top: 1rem;
    margin-right: 10px;
  }

  @media (min-width: 800px) {
    right: 100px;

    .comments-header {
      min-width: 400px;
    }
  }
`;

export const StyledExchangeDetails = styled.div`
  .exchange-details-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
  }
  .payment-status {
    text-transform: capitalize;
    margin-top: 10px;
  }
`;

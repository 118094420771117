import styled from "styled-components";

export const StyledHeaderWrapper = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.swHeader == true ? "center" : "space-between"};
  padding: 0px 5.5%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  background: #fff;
  position: fixed;
  width: 100%;
  max-width: 1920px;
  z-index: 99;
  font-family: ${(props) => props.customFont};
  
  .back {
    color: var(--color-primary);
    font-weight: 500;
    text-transform: Capitalize;
    font-size: 1.25rem;
    font-family: "Public Sans";
  }

  .change {
    color: var(--color-primary);
    text-decoration: underline;
    font-size: 1.25rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .brand-logo {
    display: block;
    max-height: 50px;
    margin: auto;
    width: 100%;
  }

  @media (max-width: 800px) {
    padding: 0 15px;
    margin-bottom: 10px;

    .back {
      font-size: 0.75rem;
    }

    .brand-logo {
      padding: 0 10px;
      max-width: 100px;
    }

    .change {
      font-size: 0.75rem;
    }
  }
`;

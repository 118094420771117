import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  newProduct: "",
  quantity: 0,
  return_type: "",
  selected_product: [],
  selected_group_products: [],
  selected_reason: null,
  selected_sub_reason: null,
  productid: null,
  uploads: [],
  previews: [],
  mobSelected: false,
  imageUploads: [],
  productType: null,
  proceedBool: false,
  uploadMediaIds: [],
};

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addNewProduct: (state, { payload }) => {
      state.newProduct = payload;
    },
    returnQuantity: (state, { payload }) => {
      state.quantity = payload;
    },
    returnType: (state, { payload }) => {
      state.return_type = payload;
    },
    addProductid: (state, { payload }) => {
      state.productid = payload;
    },
    addSelectedProduct: (state, { payload }) => {
      state.selected_product = payload;
    },
    addSelectedGroupProducts: (state, { payload }) => {
      state.selected_group_products = payload;
    },
    addSelectedReason: (state, { payload }) => {
      state.selected_reason = payload;
    },
    addSelectedSubReason: (state, { payload }) => {
      state.selected_sub_reason = payload;
    },
    addUploads: (state, { payload }) => {
      state.uploads = payload;
    },
    addPreviews: (state, { payload }) => {
      state.previews = payload;
    },
    mobSelected: (state, { payload }) => {
      state.mobSelected = payload;
    },
    setImageUploads: (state, { payload }) => {
      state.imageUploads = payload;
    },
    addProductType: (state, { payload }) => {
      state.productType = payload;
    },
    addProceedBool: (state, { payload }) => {
      state.proceedBool = payload;
    },
    addUploadMediaIds: (state, { payload }) => {
      state.uploadMediaIds = payload;
    },
  },
});

export const {
  addNewProduct,
  returnQuantity,
  returnType,
  addSelectedProduct,
  addSelectedGroupProducts,
  addSelectedReason,
  addSelectedSubReason,
  addProductid,
  addUploads,
  addPreviews,
  mobSelected,
  setImageUploads,
  addProductType,
  addProceedBool,
  addUploadMediaIds,
} = productSlice.actions;

export const getNewProduct = (state) => state.productReducer.newProduct;

export const getReturnQuantity = (state) => state.productReducer.quantity;

export const getReturnType = (state) => state.productReducer.return_type;

export const getProductid = (state) => state.productReducer.productid;

export const getSelectedProduct = (state) =>
  state.productReducer.selected_product;

export const getSelectedGroupProducts = (state) =>
  state.productReducer.selected_group_products;

export const getSelectedReason = (state) =>
  state.productReducer.selected_reason;

export const getSelectedSubReason = (state) =>
  state.productReducer.selected_sub_reason;

export const getUploads = (state) => state.productReducer.uploads;

export const getPreviews = (state) => state.productReducer.previews;

export const getSelectedMob = (state) => state.productReducer.mobSelected;

export const getImageUploads = (state) => state.productReducer.imageUploads;

export const getProductType = (state) => state.productReducer.productType;

export const getProceedBool = (state) => state.productReducer.proceedBool;

export const getUploadMediaIds = (state) => state.productReducer.uploadMediaIds;

export default productSlice.reducer;

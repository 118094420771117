import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
} from "@mui/material";
import { PickupAddress } from "../Orders/Address";
import Header from "../common/Header";
import ProductsDisplay from "../Products";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { StyledExchange, StyledPaymentDiv, StyledReturnCart } from "./styled";
import { Controller, useForm } from "react-hook-form";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrders,
  getSelectedOrder,
  getUUID,
  getWebSelected,
} from "features/orders/orderSlice";
import CartProducts from "components/common/CartProducts/CartProducts";
import {
  addRefundJSON,
  getRefundJSON,
  getRefundSource,
  getReturnedProducts,
} from "features/returnedProducts/returnedProductSlice";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { PriceBox } from "components/common/PriceBox";
import {
  createRequest,
  differencePayment,
  paymentStatus,
  paymentValidate,
  // uploadMedia,
} from "utils/api";
import { getAddress, getShipmentMethod } from "features/address/addresSlice";
import {
  getPreviews,
  getUploadMediaIds,
  getUploads,
} from "features/product/productSlice";
import { addReturnRequestId } from "features/successProducts/successProductsSlice";
import { ClipLoader } from "react-spinners";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import MissingForm from "components/common/MissingForm";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getEmail, getFirstName, getLastName } from "features/user/userSlice";
import ErrorModal from "components/Orders/Address/ErrorModal";

function ReturnCart() {
  const location = useLocation();

  const [openBankForm, setOpenBankForm] = useState(false);
  const [accountNumber, setAccountNumber] = useState(null);
  const [accHolderName, setAccHolderName] = useState(null);
  const [confirmAccNumber, setConfirmAccNumber] = useState(null);
  const [hitValidateApi, setHitValidateApi] = useState("validate");
  const [ifsc, setIfsc] = useState(null);
  const [requestPaymentStatus, setRequestPaymentStatus] = useState(null);
  const refundSource = useSelector(getRefundSource);
  const merchantConfig = useSelector(getMerchantConfig);
  const rmsConfig = useSelector(getRmsConfig);
  const allOrders = useSelector(getOrders);
  const selectedOrder = useSelector(getSelectedOrder);
  const shipmentMethod = useSelector(getShipmentMethod);
  const addressDetails = useSelector(getAddress);
  const uploads = useSelector(getPreviews);
  const previews = useSelector(getUploads);
  const returnedProducts = useSelector(getReturnedProducts);
  const [requestCreated, setRequestCreated] = useState(false);
  const webSelected = useSelector(getWebSelected);
  const unique_id = useSelector(getUUID);
  const [upiValue, setUpiValue] = useState("");
  const [outOfStock, setOutOfStock] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false);
  // const [paymentModal, setPaymentModal] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [refundModeError, setRefundModeError] = useState(false);
  const [calculatingLoader, setCalculatingLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const missingFirstName = useSelector(getFirstName);
  const missingLastName = useSelector(getLastName);
  const missingEmail = useSelector(getEmail);
  const uploadedMediaIds = useSelector(getUploadMediaIds);
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  const transaction_id =
    params.get("razorpay_invoice_id") || params.get("txn_id");
  const order_id = params.get("order_id");
  const reference_id = params.get("reference_id");
  const rdv = params.get("rdv");

  const upload_media =
    uploads !== undefined && uploads !== null && uploads.length <= 0
      ? false
      : true;

  const [updatedReturnedProducts, setUpdatedReturnedProducts] =
    useState(returnedProducts);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const submitHandler = ({ accountnum, name, confirmAccountnum, ifsc }) => {
    setOpenBankForm(false);
    setAccountNumber(accountnum);
    setAccHolderName(name);
    setConfirmAccNumber(confirmAccountnum);
    setIfsc(ifsc);
    dispatch(
      addRefundJSON({
        bank_details: {
          bank_account_number: accountnum,
          confirm_bank_account_number: confirmAccountnum,
          bank_account_holder_name: name,
          bank_ifsc_code: ifsc,
        },
        refund_method: "bank_transfer",
      })
    );
  };

  const handleUPISubmit = ({ upi }) => {
    setUpiValue(upi);
    setOpenBankForm(false);
    setAccountNumber(null);
    setAccHolderName(null);
    dispatch(
      addRefundJSON({
        upi: {
          upi_code: upi,
        },
        refund_method: "upi",
      })
    );
  };

  const dispatch = useDispatch();
  toast.configure();

  const isExchange = updatedReturnedProducts.some((a) =>
    a.return_type == "exchange" ? true : false
  );
  const isRefund = updatedReturnedProducts.some((a) =>
    a.return_type == "refund" ? true : false
  );
  const return_type =
    isExchange && isRefund
      ? "refund_exchange"
      : isExchange
      ? "exchange"
      : isRefund
      ? "refund"
      : reference_id && reference_id !== null && reference_id.includes("REXC")
      ? "refund_exchange"
      : "exchange";
  const refund_json = useSelector(getRefundJSON);

  const selectedItem = Object.values(allOrders).filter(
    (a) => a.order_number === selectedOrder.order_number
  );
  const [firstNameCheck, setFirstNameCheck] = useState(
    addressDetails.firstName ||
      missingFirstName ||
      (selectedItem &&
        selectedItem !== null &&
        selectedItem[0].customer_first_name)
  );
  const [lastNameCheck, setLastNameCheck] = useState(
    addressDetails.lastName ||
      missingLastName ||
      (selectedItem &&
        selectedItem !== null &&
        selectedItem[0].customer_last_name)
  );
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    selectedItem[0].user_phone_number
  );
  const [userEmail, setUserEmail] = useState(
    missingEmail || selectedItem[0].user_email
  );
  let accnum = watch("accountnum");
  const format = /[\!\@\$\%\^\&\*\)\(\+\=\<\>\{\}\[\]\;\|\~\`\_\?]/;
  const [wrongAddress, setWrongAddress] = useState(false);
  const [userInfoError, setUserInfoError] = useState(false);
  const [addressChange, setAddressChange] = useState(false);

  useEffect(() => {
    const totalAddressLength =
      addressDetails.addressLine1 +
      (addressDetails.addressLine2 && addressDetails.addressLine2 !== null
        ? addressDetails.addressLine2.length
        : 0);
    if (selectedItem !== null) {
      if (
        format.test(addressDetails.pincode) ||
        format.test(addressDetails.addressLine1) ||
        format.test(addressDetails.addressLine2) ||
        format.test(addressDetails.state) ||
        format.test(addressDetails.city) ||
        addressDetails.addressLine1.length < 15 ||
        (addressDetails.addressLine2 &&
          addressDetails.addressLine2 !== null &&
          addressDetails.addressLine2 != "" &&
          addressDetails.addressLine2.length < 10) ||
        totalAddressLength > 150
      ) {
        setAddressChange(true);
        setWrongAddress(true);
      } else if (
        !(
          userEmail !== undefined &&
          userPhoneNumber !== undefined &&
          userEmail !== null &&
          userPhoneNumber !== null &&
          userEmail !== "" &&
          userPhoneNumber !== "" &&
          firstNameCheck &&
          lastNameCheck &&
          firstNameCheck !== null &&
          lastNameCheck !== null &&
          firstNameCheck !== "" &&
          lastNameCheck !== ""
        )
      ) {
        setAddressChange(true);
        setUserInfoError(true);
      }
    } else {
      setWrongAddress(false);
      setAddressChange(false);
      setUserInfoError(false);
    }
  }, [selectedItem[0], addressDetails]);

  useEffect(() => {
    if (transaction_id !== null && order_id !== null && reference_id !== null) {
      setRequestCreated(true);
      const paymentSuccessCheck = () => {
        paymentStatus(
          merchantConfig.merchant_id,
          transaction_id,
          reference_id,
          order_id,
          upload_media
        ).then((resp) => {
          if (resp.data.payment_status === "failed" && !resp.data.success) {
            toast.error(resp.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 6000,
              hideProgressBar: false,
              closeOnClick: true,
            });
            setRequestCreated(false);
            clearInterval(intervalId);
          } else if (
            resp.data.return_request_id &&
            resp.data.return_request_id !== null
          ) {
            dispatch(addReturnRequestId(resp.data));
            let url = "/success";
            if (encipherencode !== null && encipherencode !== undefined) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
            clearInterval(intervalId);
          } else {
            setRequestCreated(false);
            setRequestPaymentStatus("pending");
          }
        });
      };

      const intervalId = setInterval(paymentSuccessCheck, 5000);

      return () => clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (
      paymentDetails !== null &&
      paymentMethod !== "cod" &&
      paymentMethod !== "prepaid"
    ) {
      if (paymentDetails.cod && paymentDetails.prepaid) {
        setPaymentMethod("cod-prepaid");
      } else if (paymentDetails.cod && !paymentDetails.prepaid) {
        setPaymentMethod("cod");
      } else if (paymentDetails.prepaid && !paymentDetails.cod) {
        setPaymentMethod("prepaid");
      } else {
        setPaymentMethod(null);
      }
    }
    if (paymentDetails !== null) {
      if (
        paymentDetails?.offer_refund_mode_on === "item_level" &&
        paymentDetails?.item_level_refund_mode_offer_amount > 0
      ) {
        if (refundSource === paymentDetails?.offer_refund_mode) {
          const refundProducts = updatedReturnedProducts.map((a) => {
            if (a.return_type === "refund") {
              return {
                ...a,
                refund_mode_offer_amount:
                  paymentDetails?.item_level_refund_mode_offer_amount,
              };
            }
            return a;
          });
          setUpdatedReturnedProducts(refundProducts);
          setHitValidateApi("dont_validate");
          setTimeout(() => {
            setHitValidateApi("validate");
          }, 200);
        }
      }
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (rdv !== "no_validate" && hitValidateApi == "validate") {
      setCalculatingLoader(true);
      paymentValidate(
        merchantConfig.merchant_id,
        selectedItem[0].order_number,
        selectedItem[0].order_id,
        selectedItem[0].is_cod,
        selectedItem[0].total_order_price,
        return_type,
        shipmentMethod,
        updatedReturnedProducts,
        refundSource,
        selectedOrder.preapplied_exchange_charge,
        selectedOrder.preapplied_refund_charge
      )
        .then((resp) => {
          setPaymentDetails(resp.data);
          setCalculatingLoader(false);
          setInitialLoader(false);
          setHitValidateApi("validate");
        })
        .catch((err) => {
          // console.log("err", err);
        });
    }
  }, [updatedReturnedProducts, refundSource]);

  useEffect(() => {
    setUpdatedReturnedProducts(returnedProducts);
  }, [returnedProducts]);

  const successCreate = () => {
    setRequestCreated(true);
    createRequest(
      merchantConfig.merchant_id,
      selectedItem,
      shipmentMethod,
      updatedReturnedProducts,
      rmsConfig,
      addressDetails,
      upload_media,
      return_type,
      refundSource,
      refund_json && refund_json !== null ? refund_json : {},
      userEmail,
      userPhoneNumber,
      firstNameCheck,
      lastNameCheck,
      reference_id,
      uploadedMediaIds,
      paymentDetails.refund_charges + paymentDetails.preapplied_refund_charge,
      paymentDetails.exchange_charges +
        paymentDetails.preapplied_exchange_charge,
      paymentDetails.shipping_charges,
      unique_id,
      refundSource === paymentDetails.offer_refund_mode
        ? paymentDetails.offer_refund_mode
        : null,
      refundSource === paymentDetails.offer_refund_mode
        ? paymentDetails.offer_refund_mode_on
        : null,
      refundSource === paymentDetails.offer_refund_mode
        ? paymentDetails.refund_mode_offer_amount
        : 0
    )
      .then((resp) => {
        dispatch(addReturnRequestId(resp.data));
        let url = "/success";
        if (encipherencode !== null && encipherencode !== undefined) {
          url += `?encipherencode=${encipherencode}`;
        }
        navigate(url);
      })
      .catch((err) => {
        setRequestCreated(false);
        if (err.response.data.variant_id) {
          if (
            updatedReturnedProducts
              .filter((a) => a.variant_id)
              .indexOf(err.response.data.variant_id)
          ) {
            setOutOfStock(err.response.data.variant_id);
          }
        } else if (err.response.data) {
          toast.error(err.response.data.error, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        } else {
          toast.error("Request cannot be created. Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      });
  };

  const paymentMethodValidate = () => {
    if (paymentMethod == null || paymentMethod == "cod") {
      setRequestCreated(true);
      successCreate();
    } else if (paymentMethod == "cod-prepaid") {
      toast.error("Please select the mode of Payment", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
      });
      if (window.screen.width < 800) {
        document
          .getElementById("paymentMode")
          .scrollIntoView({ behavior: "smooth" });
      }
      document.getElementById("paymentMode").style.color = "red";
    } else if (paymentMethod == "prepaid") {
      setRequestCreated(true);
      differencePayment(
        merchantConfig.merchant_id,
        firstNameCheck,
        lastNameCheck,
        paymentDetails.amount_to_pay,
        userEmail,
        userPhoneNumber,
        selectedItem[0].order_id,
        selectedItem[0].order_number,
        return_type,
        selectedItem,
        shipmentMethod,
        updatedReturnedProducts,
        rmsConfig,
        addressDetails,
        refundSource,
        refund_json && refund_json !== null ? refund_json : {},
        userEmail,
        userPhoneNumber,
        reference_id,
        uploadedMediaIds,
        selectedOrder.customer_id,
        paymentDetails.refund_charges + paymentDetails.preapplied_refund_charge,
        paymentDetails.exchange_charges +
          paymentDetails.preapplied_exchange_charge,
        paymentDetails.shipping_charges,
        unique_id
      )
        .then((resp) => {
          window.location.href = resp.data.payment_url;
          setPaymentMethod(null);
        })
        .catch((err) => {
          // console.log(err);
          toast.error("Something went wrong. Please try again", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setRequestCreated(false);
        })
        .finally(() => {
          // setPaymentModal(false);
        });
    }
  };

  const createReturnRequest = () => {
    setRefundModeError(false);
    if (return_type !== "refund") {
      if (return_type === "refund_exchange") {
        if (refundSource && refundSource !== null) {
          if (refundSource == "bank_transfer" || refundSource == "upi") {
            if (Object.keys(refund_json).length > 0) {
              paymentMethodValidate();
            } else {
              toast.error("Please select the mode of refund", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 6000,
                hideProgressBar: false,
                closeOnClick: true,
              });
              setRefundModeError(true);
              if (window.screen.width < 800) {
                document
                  .getElementById("Returnmode")
                  .scrollIntoView({ behavior: "smooth" });
              }
            }
          } else {
            paymentMethodValidate();
          }
        } else {
          toast.error("Please select the mode of refund", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setRefundModeError(true);
          if (window.screen.width < 800) {
            document
              .getElementById("Returnmode")
              .scrollIntoView({ behavior: "smooth" });
          }
        }
      } else if (return_type === "exchange") {
        paymentMethodValidate();
      }
    } else if (refundSource == "bank_transfer" || refundSource == "upi") {
      if (Object.keys(refund_json).length > 0) {
        if (!requestCreated) {
          setRequestCreated(true);
          successCreate();
        }
      } else {
        // throw error
        toast.error("Please select the mode of refund.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setRefundModeError(true);
        if (window.screen.width < 800) {
          document
            .getElementById("Returnmode")
            .scrollIntoView({ behavior: "smooth" });
        }
      }
    } else {
      if (!requestCreated) {
        if (refundSource !== null) {
          setRequestCreated(true);
          successCreate();
        } else {
          // throw error
          toast.error("Please select the mode of refund.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          });
          setRefundModeError(true);
          if (window.screen.width < 800) {
            document
              .getElementById("Returnmode")
              .scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  };

  return (
    <StyledReturnCart
      sharpEdge={!merchantConfig.use_rounded_edge_buttons}
      customFont={
        merchantConfig.google_font_family !== null
          ? merchantConfig.google_font_family
          : "Poppins,sans-serif"
      }
    >
      <Header
        ordername={selectedOrder.order_name}
        requestPaymentStatus={requestPaymentStatus}
      />
      <div
        className="return-request"
        style={requestPaymentStatus == "pending" ? { display: "none" } : {}}
      >
        <p className="product-number">
          {updatedReturnedProducts.length} product
          {updatedReturnedProducts.length > 1 ? "s" : ""} in return cart
        </p>
        <Button
          type="primary"
          onClick={() => {
            createReturnRequest();
          }}
          variant="contained"
          className="request-button"
          disabled={
            !(updatedReturnedProducts.length > 0) ||
            requestCreated ||
            initialLoader ||
            calculatingLoader ||
            wrongAddress ||
            userInfoError ||
            requestPaymentStatus == "pending"
          }
        >
          Create return request
        </Button>
      </div>

      {requestCreated && <div className="overlay" />}

      {requestCreated ? (
        <div
          style={{
            height: "100vh",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {" "}
          <ClipLoader
            color="#333"
            size={50}
            speedMultiplier={0.75}
            cssOverride={{ borderWidth: "6px" }}
          />{" "}
          <p
            style={{ marginTop: "10px", fontSize: "16px", textAlign: "center" }}
          >
            Loading... <br /> Please do not refresh this page
          </p>
        </div>
      ) : requestPaymentStatus == "pending" ? (
        <div
          style={{
            height: "100vh",
            background: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            color: "#333",
            textAlign: "center",
            padding: "0px 15px",
          }}
        >
          <p
            style={{
              fontWeight: "700",
              fontSize: "20px",
              display: "flex",
              marginTop: "10px",
            }}
          >
            Your payment is still under processing.{" "}
          </p>
          <p style={{ marginTop: "10px" }}>
            Request will be placed once the payment is successful and you will
            be notified accordingly.
          </p>
          <p style={{ color: "#3339", marginTop: "10px" }}>
            For your reference, Here is your transaction id: {transaction_id}
          </p>
          <p style={{ marginTop: "30px" }}>
            {" "}
            Please do not go back or refersh this page{" "}
          </p>
          <p style={{ display: "flex", alignItems: "center" }}>
            {" "}
            Fetching current payment status... <ClipLoader size={20} />
          </p>
        </div>
      ) : (
        <Fragment>
          <StyledExchange
            returnedProducts={updatedReturnedProducts}
            webSelected={webSelected}
            location={location.pathname}
            outOfStock={outOfStock}
            refundModeError={refundModeError}
          >
            <div className="products-return">
              <p className="select">Items in the return cart</p>
              <Grid
                alignItems="center"
                container
                rowSpacing={{ xs: 2, sm: 4 }}
                gridTemplateColumns="repeat(12, 1fr)"
                columnSpacing={4}
                className="cart-products"
              >
                {updatedReturnedProducts.length > 0 ? (
                  updatedReturnedProducts.map((product, i) => {
                    return (
                      <Grid key={i} justifyContent="center" item xs={12}>
                        <CartProducts
                          product={product}
                          location={location.pathname}
                          returnedProducts={updatedReturnedProducts}
                          outOfStock={outOfStock}
                        />
                      </Grid>
                    );
                  })
                ) : (
                  <Grid justifyContent="center" item xs={12}>
                    <p>No items in your Return Cart</p>
                  </Grid>
                )}
              </Grid>
              {updatedReturnedProducts.length !==
                selectedOrder.order_items.length && (
                <p
                  className="select"
                  style={{ fontSize: "16px", marginTop: "20px" }}
                >
                  Select products for return or exchange
                </p>
              )}
              <div className="product-display" style={{ padding: "0px" }}>
                <ProductsDisplay />
              </div>
            </div>

            <div
              style={{
                borderRight: "2px dashed rgba(17, 17, 17, 0.2)",
                flex: "0.1",
              }}
            />

            <div className="price-details">
              {addressChange && (
                <ErrorModal
                  wrongAddress={wrongAddress}
                  setWrongAddress={setWrongAddress}
                  userInfoError={userInfoError}
                  setUserInfoError={setUserInfoError}
                  selectedItem={selectedItem}
                  format={format}
                  firstName={firstNameCheck}
                  setFirstName={setFirstNameCheck}
                  lastName={lastNameCheck}
                  setLastName={setLastNameCheck}
                  userPhoneNumber={userPhoneNumber}
                  setUserPhoneNumber={setUserPhoneNumber}
                  userEmail={userEmail}
                  setUserEmail={setUserEmail}
                  setAddressChange={setAddressChange}
                  addressDetails={addressDetails}
                />
              )}
              <p
                className="select pricing"
                style={{
                  padding: "0px 0px 15px",
                  margin: "0px",
                  fontWeight: "600",
                }}
              >
                Pricing & Shipping Details
              </p>

              <div className="box address">
                <PickupAddress
                  setAddressChange={setAddressChange}
                  edit={rmsConfig.enable_address_edit}
                  updatedAddress={addressDetails}
                  rmsConfig={rmsConfig}
                  serviceable={
                    shipmentMethod == "customer_ships_back" ? false : true
                  }
                />
              </div>

              {initialLoader ? (
                <div>
                  <Skeleton style={{ width: "100%" }} height={50} />
                  <Skeleton style={{ width: "100%" }} height={50} />
                  <Skeleton style={{ width: "100%" }} height={50} />
                </div>
              ) : (
                <div>
                  {updatedReturnedProducts.length > 0 &&
                    paymentDetails !== null &&
                    (return_type === "refund" ||
                      return_type === "refund_exchange") && (
                      <PriceBox
                        infoType="Return"
                        setOpenBankForm={setOpenBankForm}
                        refundSource={refundSource}
                        accountNumber={accountNumber}
                        confirmAccNumber={confirmAccNumber}
                        accHolderName={accHolderName}
                        ifsc={ifsc}
                        upiValue={upiValue}
                        returnType={return_type}
                        returnedProducts={updatedReturnedProducts}
                        paymentDetails={paymentDetails}
                        setPaymentDetails={setPaymentDetails}
                        calculatingLoader={calculatingLoader}
                        setCalculatingLoader={setCalculatingLoader}
                      />
                    )}

                  {paymentDetails &&
                    paymentDetails !== null &&
                    return_type !== "refund" && (
                      <StyledPaymentDiv>
                        <p className="payment-title">
                          Exchange Payment Details
                        </p>
                        <div className="subtotal-statement">
                          <p style={{ color: "#333333B2" }}>
                            Subtotal of Products (Exchange)
                          </p>
                          <p
                            style={{
                              color: "#333333",
                              fontWeight: "500",
                              whiteSpace: "nowrap",
                              marginLeft: "1rem",
                            }}
                          >
                            ₹ {paymentDetails.old_item_total}
                          </p>
                        </div>

                        <div className="subtotal-statement">
                          <p style={{ color: "#333333B2" }}>
                            Subtotal of New Products (Exchange)
                          </p>
                          <p
                            style={{
                              color: "#333333",
                              fontWeight: "500",
                              marginLeft: "1rem",
                              whiteSpace: "nowrap",
                            }}
                          >
                            ₹ {paymentDetails.new_item_total}
                          </p>
                        </div>

                        {paymentDetails.exchange_charges +
                          paymentDetails.preapplied_exchange_charge >
                          0 && (
                          <div className="subtotal-statement">
                            <p style={{ color: "#333333B2" }}>
                              {paymentDetails.exchange_charge_label &&
                              paymentDetails.exchange_charge_label !== ""
                                ? paymentDetails.exchange_charge_label
                                : "Handling Charges"}
                            </p>
                            <p
                              style={{
                                color: "#e74c3c",
                                fontWeight: "500",
                                marginLeft: "1rem",
                                whiteSpace: "nowrap",
                              }}
                            >
                              - ₹{" "}
                              {paymentDetails.exchange_charges +
                                paymentDetails.preapplied_exchange_charge}
                            </p>
                          </div>
                        )}

                        {return_type === "exchange" &&
                          paymentDetails.shipping_charges > 0 && (
                            <div className="subtotal-statement">
                              <p style={{ color: "#333333B2" }}>
                                {paymentDetails.shipping_charge_label &&
                                paymentDetails.shipping_charge_label !== ""
                                  ? paymentDetails.shipping_charge_label
                                  : "Shipping Charges"}
                              </p>
                              <p
                                style={{
                                  color: "#e74c3c",
                                  fontWeight: "500",
                                  marginLeft: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                - ₹ {paymentDetails.shipping_charges}
                              </p>
                            </div>
                          )}

                        <div
                          style={{
                            borderTop: "1px dashed #333333B2",
                            borderBottom: "1px dashed #333333B2",
                            padding: "1rem 0px",
                            margin: "1.25rem 0",
                          }}
                          className="subtotal-statement"
                        >
                          {paymentDetails.amount_to_pay >= 0 ? (
                            <Fragment>
                              <p style={{ color: "#333333B2" }}>
                                Total Payable Amount
                              </p>
                              <p
                                style={{
                                  color: "#333333",
                                  fontWeight: "600",
                                  marginLeft: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {calculatingLoader
                                  ? "Calculating..."
                                  : `₹ ${paymentDetails.amount_to_pay}`}
                              </p>
                            </Fragment>
                          ) : paymentDetails.reimbursement > 0 ? (
                            <Fragment>
                              <p style={{ color: "#333333B2" }}>You will get</p>
                              <p
                                style={{
                                  color: "#16AC37",
                                  fontWeight: "600",
                                  marginLeft: "1rem",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                ₹ {paymentDetails.reimbursement}
                              </p>
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </div>

                        {(paymentDetails.reimbursement > 0 ||
                          paymentDetails.amount_to_pay < 0) && (
                          <p
                            style={{
                              color: "#333333B2",
                              fontSize: "12px",
                            }}
                            className="payment-reimbursement"
                          >
                            You will be reimbursed an amount of ₹
                            <span style={{ color: "#16AC37" }}>
                              {Number(paymentDetails.reimbursement)}
                            </span>
                            * (TnC apply)
                          </p>
                        )}
                        {paymentDetails.cod && paymentDetails.prepaid && (
                          <FormControl
                            className="payment-form"
                            style={{
                              width: "100%",
                              margin: "1rem 0",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormLabel
                              id="paymentMode"
                              style={{
                                color: "#333333",
                                marginBottom: "0px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Select payment Mode:
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="paymentMode"
                              name="paymentMode"
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                              }}
                              className="payment-radio-buttons"
                            >
                              <FormControlLabel
                                value="prepaid"
                                checked={paymentMethod == "prepaid"}
                                control={<Radio size="small" />}
                                label={
                                  <span style={{ marginRight: "10px" }}>
                                    Prepaid
                                  </span>
                                }
                                onChange={() => {
                                  setPaymentMethod("prepaid");
                                  document.getElementById(
                                    "paymentMode"
                                  ).style.color = "#333";
                                }}
                              />
                              <FormControlLabel
                                value="cod"
                                checked={paymentMethod == "cod"}
                                control={<Radio size="small" />}
                                label={<span>COD</span>}
                                onChange={() => {
                                  setPaymentMethod("cod");
                                  document.getElementById(
                                    "paymentMode"
                                  ).style.color = "#333";
                                }}
                                style={{
                                  marginRight: "0",
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </StyledPaymentDiv>
                    )}
                </div>
              )}
            </div>
            {/* Bank Form */}

            <Drawer
              classes={{ paper: "paper-drawer" }}
              anchor="bottom"
              open={openBankForm}
              onClose={() => setOpenBankForm(false)}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                    height: "80px",
                    padding: "32px",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1rem",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    {refundSource === "upi"
                      ? "Enter UPI details"
                      : "Enter Bank Details"}
                  </p>
                  <p
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenBankForm(false)}
                  >
                    {" "}
                    <CloseIcon
                      style={{ width: "24px", height: "24px", stroke: "#333" }}
                    />{" "}
                  </p>
                </div>

                {refundSource === "bank_transfer" ? (
                  <form onSubmit={handleSubmit(submitHandler)}>
                    <div style={{ padding: "20px" }}>
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{ required: true, pattern: /^\d{9,18}$/ }}
                        name="accountnum"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            autoFocus
                            id="accountnum"
                            error={Boolean(errors.accountnum)}
                            helperText={
                              errors.accountnum
                                ? errors.accountnum.type === "pattern"
                                  ? "Account number must be more than 9 numbers"
                                  : errors.accountnum.type === "required"
                                  ? "Account Number is required"
                                  : ""
                                : ""
                            }
                            label="Account Number"
                            defaultValue=""
                            fullWidth
                            inputProps={{ type: "text" }}
                            style={{ marginTop: "20px" }}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern: /^\d{9,18}$/,
                          validate: (value) =>
                            value === accnum || "Account numbers do not match",
                        }}
                        name="confirmAccountnum"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            id="confirmAccountnum"
                            error={Boolean(errors.confirmAccountnum)}
                            helperText={
                              errors.confirmAccountnum
                                ? errors.confirmAccountnum.type === "pattern"
                                  ? "Account number must be more than 9 numbers"
                                  : errors.confirmAccountnum.type === "required"
                                  ? "Account Number is required"
                                  : errors.confirmAccountnum.type === "validate"
                                  ? "Account numbers do not match"
                                  : ""
                                : ""
                            }
                            label="Re-enter Account Number"
                            defaultValue=""
                            fullWidth
                            inputProps={{ type: "text" }}
                            style={{ marginTop: "20px" }}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{ required: true, minLength: 2 }}
                        name="name"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="name"
                            label="Account Holder Name"
                            error={Boolean(errors.name)}
                            helperText={
                              errors.name
                                ? errors.name.type === "minLength"
                                  ? "Name length must be more than 2"
                                  : "Name is required"
                                : ""
                            }
                            inputProps={{ type: "text" }}
                            style={{ marginTop: "20px" }}
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true,
                          pattern: /^[A-Z]{4}0[A-Z0-9]{6}$/,
                        }}
                        name="ifsc"
                        render={({ field }) => (
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="ifsc"
                            label="IFSC"
                            error={Boolean(errors.ifsc)}
                            helperText={
                              errors.ifsc
                                ? errors.ifsc.type === "pattern"
                                  ? "IFSC code doesn't match required pattern"
                                  : "IFSC code is required"
                                : ""
                            }
                            inputProps={{ type: "text" }}
                            style={{ marginTop: "20px" }}
                            {...field}
                          />
                        )}
                      />
                    </div>

                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        padding: "1rem",
                        borderRadius: !merchantConfig.use_rounded_edge_buttons
                          ? "0px"
                          : "4px",
                        margin: "40px auto",
                        display: "block",
                        cursor: "pointer",
                        textTransform: "capitalize",
                      }}
                    >
                      Save & Continue
                    </Button>
                  </form>
                ) : refundSource === "upi" ? (
                  <form
                    onSubmit={handleSubmit(handleUPISubmit)}
                    style={{ padding: "20px" }}
                  >
                    <Controller
                      control={control}
                      defaultValue=""
                      rules={{
                        required: true,
                      }}
                      name="upi"
                      render={({ field }) => (
                        <TextField
                          variant="outlined"
                          fullWidth
                          id="upi"
                          label="UPI ID"
                          error={Boolean(errors.upi)}
                          helperText={errors.upi && "UPI code is required"}
                          inputProps={{ type: "text" }}
                          style={{ marginTop: "20px" }}
                          {...field}
                        />
                      )}
                    />
                    {refundSource === "upi" && (
                      <p
                        style={{
                          marginTop: "5px",
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        Example: yourname@okxxx
                      </p>
                    )}
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      style={{
                        padding: "1rem",
                        borderRadius: !merchantConfig.use_rounded_edge_buttons
                          ? "0px"
                          : "4px",
                        margin: "40px auto",
                        display: "block",
                        cursor: "pointer",
                        textTransform: "capitalize",
                      }}
                    >
                      Save & Continue
                    </Button>
                  </form>
                ) : (
                  ""
                )}
              </div>
            </Drawer>

            {/* Missing Details Modal  */}

            {/* <MissingForm
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              createReturnRequest={createReturnRequest}
              userEmail={userEmail}
              setUserEmail={setUserEmail}
              userPhoneNumber={userPhoneNumber}
              setUserPhoneNumber={setUserPhoneNumber}
              firstNameCheck={firstNameCheck}
              setFirstNameCheck={setFirstNameCheck}
              lastNameCheck={lastNameCheck}
              setLastNameCheck={setLastNameCheck}
              type="request"
              selectedItem={selectedItem}
              missingEmail={missingEmail}
              missingLastName={missingLastName}
              missingFirstName={missingFirstName}
              addressDetails={addressDetails}
            /> */}
          </StyledExchange>
          {merchantConfig.whatsapp_help &&
            merchantConfig.whatsapp_help !== null && (
              <a
                href={merchantConfig.whatsapp_help}
                target="_blank"
                rel="noreferrer"
                className="help"
                style={{ display: "block" }}
              >
                Need Help?
              </a>
            )}
        </Fragment>
      )}
    </StyledReturnCart>
  );
}

export default ReturnCart;

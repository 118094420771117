import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { ReactComponent as EditIcon } from "assets/edit.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import BankForm from "../BankForm";
import { Controller, useForm } from "react-hook-form";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import {
  addRefundJSON,
  addRefundSource,
} from "features/returnedProducts/returnedProductSlice";
import parse from "html-react-parser";
import { getOrders, getSelectedOrder } from "features/orders/orderSlice";
import { submitDetailsApi } from "utils/api";
import { toast } from "react-toastify";
import { StyledPaymentDiv } from "components/ReturnCart/styled";

export const PriceBox = ({
  infoType,
  setOpenBankForm,
  refundSource,
  returnItems,
  accHolderName,
  accountNumber,
  confirmAccNumber,
  ifsc,
  upiValue,
  setReturnItems,
  returnType,
  paymentDetails,
  calculatingLoader,
  returnedProducts,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const product_applicable_refund_modes =
    returnedProducts &&
    returnedProducts.length > 0 &&
    returnedProducts
      .filter((a) => a.return_type === "refund")
      .map((a) => a.applicable_refund_modes)
      .flat();

  // Selectors
  const rmsConfig = useSelector(getRmsConfig);
  const selectedOrder = useSelector(getSelectedOrder);
  const merchantConfig = useSelector(getMerchantConfig);
  const allOrders = useSelector(getOrders);

  // States
  const [submitDetails, setSubmitDetails] = useState(false);
  const [detailsSubmitted, setDetailsSubmitted] = useState(false);
  const [detailsMethod, setDetailsMethod] = useState(null);
  const [submittingDetails, setSubmitttingDetails] = useState(false);
  const [refundOptions, setRefundOptions] = useState(0);
  const [specialRefundMode, setSpecialRefundMode] = useState(null);
  const [specialRefundModeLabel, setSpecialRefundModeLabel] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  toast.configure();

  const handleSubmitUPIDetails = ({ upi }) => {
    setSubmitttingDetails(true);
    submitDetailsApi(
      merchantConfig.merchant_id,
      returnItems.id,
      "upi",
      "",
      0,
      0,
      "",
      upi
    )
      .then((resp) => {
        setDetailsSubmitted(true);
        toast.success("Details have been successfully submitted", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setReturnItems({
          ...returnItems,
          refund_json: {
            upi: {
              upi_code: upi,
            },
          },
        });
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Error! Please try again in some time.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .finally(() => {
        setSubmitttingDetails(false);
      });
  };

  const handleSubmitBankDetails = ({
    accountnum,
    name,
    confirmAccountnum,
    ifsc,
  }) => {
    setSubmitttingDetails(true);
    submitDetailsApi(
      merchantConfig.merchant_id,
      returnItems.id,
      "bank_transfer",
      name,
      Number(accountnum),
      Number(confirmAccountnum),
      ifsc,
      ""
    )
      .then((resp) => {
        setDetailsSubmitted(true);
        setReturnItems({
          ...returnItems,
          refund_json: {
            bank_details: {
              bank_account_holder_name: name,
              bank_account_number: accountnum,
            },
          },
        });
        toast.success("Details have been successfully submitted", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .catch((err) => {
        // console.log(err);
        toast.error("Error! Please try again in some time.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      })
      .finally(() => {
        setSubmitttingDetails(false);
      });
  };

  const onlyOne = (a, b, c, d, e, f) =>
    !([a, b, c, d, e, f].filter(Boolean).length - 1);

  function updateRefundSource(source) {
    setOpenBankForm(false);
    dispatch(addRefundSource(source));
  }

  const BonusChargesSection = paymentDetails?.applicable_offer_amount > 0 && (
    <p
      style={{
        background: "#e7fcf3",
        color: "#16AC37",
        fontWeight: "600",
        padding: "5px 10px",
        fontSize: "12px",
        borderRadius: "4px",
        width: "fit-content",
        marginLeft: "10px",
      }}
    >
      Bonus ₹ {paymentDetails?.applicable_offer_amount}{" "}
    </p>
  );

  const ChargesSection = ({ source }) => {
    const { all_mode_charges } = paymentDetails || {};

    if (all_mode_charges && Object.keys(all_mode_charges).length > 0) {
      let sourceMap = {};
      if (all_mode_charges.all_modes) {
        sourceMap = {
          bank_transfer: all_mode_charges.all_modes || 0,
          upi: all_mode_charges.all_modes || 0,
          refund_to_source: all_mode_charges.all_modes || 0,
          coupon: all_mode_charges.all_modes || 0,
          gift_card: all_mode_charges.all_modes || 0,
          store_credit: all_mode_charges.all_modes || 0,
        };
      } else {
        sourceMap = {
          bank_transfer: all_mode_charges.bank_transfer || 0,
          upi: all_mode_charges.bank_transfer || 0,
          refund_to_source: all_mode_charges.source_refund || 0,
          coupon: all_mode_charges.coupon || 0,
          gift_card: all_mode_charges.gift_card || 0,
          store_credit: all_mode_charges.store_credit || 0,
        };
      }
      if (sourceMap[source] > 0) {
        return (
          <p
            style={{
              background: "#FDF5F8",
              color: "#f1416c",
              fontWeight: "600",
              padding: "5px 10px",
              fontSize: "12px",
              borderRadius: "4px",
              width: "fit-content",
              marginLeft: "10px",
              textWrap: "nowrap",
            }}
          >
            Handling Charges ₹ {sourceMap[source]}
          </p>
        );
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (location.pathname == "/return-cart") {
      if (selectedOrder.applicable_refund_modes.length > 0) {
        if (selectedOrder.applicable_refund_modes.length === 1) {
          if (selectedOrder.applicable_refund_modes === "coupon_code") {
            dispatch(addRefundSource("coupon"));
          } else if (selectedOrder.applicable_refund_modes === "gift_card") {
            dispatch(addRefundSource("gift_card"));
          } else if (
            selectedOrder.applicable_refund_modes === "source_refund"
          ) {
            dispatch(addRefundSource("refund_to_source"));
          } else if (selectedOrder.applicable_refund_modes === "store_credit") {
            dispatch(addRefundSource("credits_yard"));
          }
        }
      } else if (
        rmsConfig.show_only_gc_and_source_refund_for_prepaid_orders &&
        !selectedOrder.is_cod
      ) {
        if (
          rmsConfig.refund_to_source &&
          !rmsConfig.gift_card &&
          selectedOrder.source_refund
        ) {
          setRefundOptions(1);
          dispatch(addRefundSource("refund_to_source"));
        } else if (!rmsConfig.refund_to_source && rmsConfig.gift_card) {
          setRefundOptions(1);
          dispatch(addRefundSource("gift_card"));
        }
      } else if (
        selectedOrder.is_cod &&
        rmsConfig.show_only_gc_for_cod_orders
      ) {
        if (rmsConfig.gift_card) {
          setRefundOptions(1);
          dispatch(addRefundSource("gift_card"));
        }
      } else {
        if (
          onlyOne(
            rmsConfig.customer_bank_enabled,
            rmsConfig.customer_upi_enabled,
            rmsConfig.gift_card,
            rmsConfig.coupon_refund_enabled,
            rmsConfig.store_credit && rmsConfig.credits_yard,
            !selectedOrder.is_cod &&
              rmsConfig.refund_to_source &&
              selectedOrder.source_refund
          )
        ) {
          setRefundOptions(1);
          if (rmsConfig.customer_bank_enabled) {
            updateRefundSource("bank_transfer");
            if (!rmsConfig.use_direct_transfer_for_refund) {
              dispatch(
                addRefundJSON({
                  bank_details: {
                    bank_account_number: accountNumber,
                    confirm_bank_account_number: confirmAccNumber,
                    bank_account_holder_name: accHolderName,
                    bank_ifsc_code: ifsc,
                  },
                  refund_method: "bank_transfer",
                })
              );
            } else {
              setOpenBankForm(true);
            }
          } else if (rmsConfig.customer_upi_enabled) {
            updateRefundSource("upi");
            if (!rmsConfig.use_direct_transfer_for_refund) {
              dispatch(
                addRefundJSON({
                  upi: {
                    upi_code: upiValue,
                  },
                  refund_method: "upi",
                })
              );
            } else {
              setOpenBankForm(true);
            }
          } else if (rmsConfig.gift_card) {
            updateRefundSource("gift_card");
          } else if (rmsConfig.coupon_refund_enabled) {
            updateRefundSource("coupon");
          } else if (rmsConfig.store_credit && rmsConfig.credits_yard) {
            updateRefundSource("credits_yard");
          } else if (
            !selectedOrder.is_cod &&
            rmsConfig.refund_to_source &&
            selectedOrder.source_refund
          ) {
            updateRefundSource("refund_to_source");
          }
        }
      }

      // dispatch(addRefundSource(rmsConfig.offer_refund_mode));
      // setSpecialRefundMode(rmsConfig.offer_refund_mode);
      setSpecialRefundModeLabel(rmsConfig.offer_refund_mode_label);
    }
  }, []);

  const RadioLabel = ({ sourceValue, sourceLabel }) => {
    return (
      <FormControlLabel
        id={sourceValue}
        value={sourceValue}
        control={
          <Radio
            color="default"
            defaultChecked={refundOptions === 1}
            size="small"
            checked={refundSource === sourceValue}
            style={
              specialRefundMode === sourceValue ? { color: "#16AC37" } : {}
            }
            onChange={() => {
              updateRefundSource(sourceValue);
              if (sourceValue === "bank_transfer" || sourceValue === "upi") {
                if (
                  !rmsConfig.use_direct_transfer_for_refund &&
                  (product_applicable_refund_modes.length > 0 ||
                    selectedOrder.applicable_refund_modes.length === 0 ||
                    (selectedOrder.applicable_refund_modes.includes(
                      "payment_link"
                    ) &&
                      (product_applicable_refund_modes.length === 0 ||
                        product_applicable_refund_modes.includes(
                          "payment_link"
                        ))))
                ) {
                  if (sourceValue === "upi") {
                    dispatch(
                      addRefundJSON({
                        upi: {
                          upi_code: upiValue,
                        },
                        refund_method: "upi",
                      })
                    );
                  } else {
                    dispatch(
                      addRefundJSON({
                        bank_details: {
                          bank_account_number: accountNumber,
                          confirm_bank_account_number: confirmAccNumber,
                          bank_account_holder_name: accHolderName,
                          bank_ifsc_code: ifsc,
                        },
                        refund_method: "bank_transfer",
                      })
                    );
                  }
                } else {
                  setOpenBankForm(true);
                }
              }
            }}
          />
        }
        label={
          <p
            className="radio-label"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {sourceLabel}
            {specialRefundMode === sourceValue && BonusChargesSection}
            <ChargesSection source={sourceValue} />
          </p>
        }
      />
    );
  };

  return (
    <div className="box" id={`${infoType}mode`}>
      {/* Excahnge Box  */}

      {infoType == "Exchange" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {returnItems &&
            returnItems !== null &&
            returnItems.exchange_details.length > 0 && (
              <div style={{ display: "block", marginTop: "5px" }}>
                <p className="title"> Exchange Details </p>
                {returnItems.exchange_details.map((detail) => {
                  return (
                    <div style={{ marginTop: "10px" }}>
                      <p style={{ textTransform: "capitalize" }}>
                        Status: {detail.exchange_status.replace("_", " ")}
                      </p>
                      <p>
                        {" "}
                        Exchange order number : {
                          detail.exchange_order_number
                        }{" "}
                      </p>
                      <Button
                        style={{
                          margin: "10px 0px",
                          textTransform: "capitalize",
                        }}
                        variant="outlined"
                      >
                        <a
                          style={{ color: "inherit", textDecoration: "none" }}
                          target="_blank"
                          rel="noreferrer"
                          href={detail.exchange_order_url}
                        >
                          View Order
                        </a>
                      </Button>
                    </div>
                  );
                })}
              </div>
            )}
        </div>
      ) : (
        (returnType == "refund" || returnType == "refund_exchange") &&
        infoType == "Return" && (
          <div>
            <p className="title" style={{ marginBottom: "10px" }}>
              Choose a Refund Mode{" "}
            </p>
            {location.pathname == "/return-cart" && (
              <div>
                <FormControl>
                  {((rmsConfig.show_only_gc_and_source_refund_for_prepaid_orders &&
                    !selectedOrder.is_cod) ||
                    (selectedOrder.is_cod &&
                      rmsConfig.show_only_gc_for_cod_orders)) &&
                  !selectedOrder.show_other_refund_modes &&
                  (selectedOrder.applicable_refund_modes.length === 0 ||
                    selectedOrder.applicable_refund_modes.includes(
                      "source_refund"
                    )) &&
                  (product_applicable_refund_modes.length === 0 ||
                    product_applicable_refund_modes.includes(
                      "source_refund"
                    )) ? (
                    <RadioGroup
                      aria-labelledby="refund-options"
                      name="refund-options"
                    >
                      {rmsConfig.refund_to_source &&
                        selectedOrder.source_refund && (
                          <RadioLabel
                            sourceLabel="Source Account"
                            sourceValue="refund_to_source"
                          />
                        )}

                      {rmsConfig.gift_card &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "gift_card"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "gift_card"
                          )) && (
                          <RadioLabel
                            sourceLabel="Gift Card"
                            sourceValue="gift_card"
                          />
                        )}

                      {!rmsConfig.gift_card &&
                        !rmsConfig.refund_to_source &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "bank_transfer"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "bank_transfer"
                          )) && (
                          <RadioLabel
                            sourceLabel="Bank Account"
                            sourceValue="bank_transfer"
                          />
                        )}
                    </RadioGroup>
                  ) : (
                    <RadioGroup
                      aria-labelledby="refund-options"
                      name="refund-options"
                    >
                      {((rmsConfig.customer_bank_enabled &&
                        selectedOrder.is_cod &&
                        rmsConfig.collect_bank_details_for_cod_orders) ||
                        (rmsConfig.customer_bank_enabled &&
                          !selectedOrder.is_cod)) &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "bank_transfer"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "bank_transfer"
                          ) ||
                          product_applicable_refund_modes.includes(
                            "payment_link"
                          )) && (
                          <RadioLabel
                            sourceLabel="Bank Account"
                            sourceValue="bank_transfer"
                          />
                        )}
                      {((rmsConfig.customer_upi_enabled &&
                        selectedOrder.is_cod &&
                        rmsConfig.collect_upi_details_for_cod_orders) ||
                        (rmsConfig.customer_upi_enabled &&
                          !selectedOrder.is_cod)) &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "bank_transfer"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "bank_transfer"
                          ) ||
                          product_applicable_refund_modes.includes(
                            "payment_link"
                          )) && (
                          <RadioLabel sourceLabel="UPI" sourceValue="upi" />
                        )}
                      {rmsConfig.coupon_refund_enabled &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "coupon_code"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "coupon_code"
                          )) && (
                          <RadioLabel
                            sourceLabel={rmsConfig.coupon_method_name}
                            sourceValue="coupon"
                          />
                        )}
                      {rmsConfig.store_credit &&
                        rmsConfig.credits_yard &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "store_credit"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "store_credit"
                          )) && (
                          <RadioLabel
                            sourceLabel={rmsConfig.store_credit_method_name}
                            sourceValue="credits_yard"
                          />
                        )}
                      {rmsConfig.gift_card &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "gift_card"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "gift_card"
                          )) && (
                          <RadioLabel
                            sourceLabel={rmsConfig.gift_card_method_name}
                            sourceValue="gift_card"
                          />
                        )}
                      {!selectedOrder.is_cod &&
                        rmsConfig.refund_to_source &&
                        selectedOrder.source_refund &&
                        (product_applicable_refund_modes.length > 0 ||
                          selectedOrder.applicable_refund_modes.length === 0 ||
                          selectedOrder.applicable_refund_modes.includes(
                            "refund_to_source"
                          )) &&
                        (product_applicable_refund_modes.length === 0 ||
                          product_applicable_refund_modes.includes(
                            "refund_to_source"
                          )) && (
                          <RadioLabel
                            sourceLabel="Source Account"
                            sourceValue="refund_to_source"
                          />
                        )}
                    </RadioGroup>
                  )}
                </FormControl>
              </div>
            )}
            {returnItems &&
            returnItems !== null &&
            returnItems.refund_details &&
            returnItems.refund_details !== null &&
            returnItems.refund_details.length > 0 ? (
              returnItems.refund_details.map((detail) => {
                return (
                  <div>
                    <p
                      style={{
                        margin: "10px 0px",
                        color: "#333",
                        textTransform: "capitalize",
                      }}
                    >
                      {" "}
                      Refund preference selected&nbsp; - &nbsp;
                      {detail.refund_method === "bank_transfer" ? (
                        "Bank transfer"
                      ) : detail.refund_method === "upi" ? (
                        "UPI"
                      ) : detail.refund_method === "refund_to_source" ? (
                        <span style={{ textTransform: "initial" }}>
                          Your refund has been processed to your original mode
                          of payment
                        </span>
                      ) : detail.refund_method == "coupon" ? (
                        rmsConfig.coupon_method_name
                      ) : detail.refund_method !== null ? (
                        detail.refund_method.replace("_", " ")
                      ) : (
                        detail.customer_preferred_refund_method ||
                        returnItems.customer_preferred_refund_method
                      )}
                    </p>
                    {detail.refund_url !== null && (
                      <p>
                        Collect your amount{" "}
                        <a
                          href={detail.refund_url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "inherit" }}
                        >
                          here
                        </a>
                      </p>
                    )}
                    {(detail.refund_method === "bank_transfer" ||
                      detail.refund_method === "bank_transfer_razorpayx" ||
                      detail.refund_method === "bank_transfer_cashfree") &&
                    rmsConfig.use_direct_transfer_for_refund ? (
                      <div>
                        {detail.account_holder_name &&
                          detail.account_holder_name !== null && (
                            <p>
                              {detail.account_holder_name} &nbsp; - &nbsp;{" "}
                              {detail.account_number.replace(
                                /^(\d+)(\d{4})/,
                                "*******$2"
                              )}
                            </p>
                          )}
                        {detail.vpa !== null && (
                          <p>
                            UPI ID:{" "}
                            {detail.vpa.replace(/^(\d+)(\d{4})/, "*******$2")}
                          </p>
                        )}
                        <p>
                          Refund Status -{" "}
                          {detail.refund_status !== null &&
                            detail.refund_status.replace("_", " ")}
                        </p>
                      </div>
                    ) : (
                      location.pathname === "/tracking" &&
                      (detail.refund_method == "bank_transfer" ||
                        detail.refund_method == "upi") &&
                      !detailsSubmitted && (
                        <div>
                          <p
                            style={{
                              color: "rgb(51, 51, 51)",
                              fontSize: "16px",
                              marginBottom: "10px",
                            }}
                          >
                            Please select the mode of refund
                          </p>
                          <FormControl>
                            <RadioGroup
                              row
                              aria-labelledby="refund-options"
                              name="refund-options"
                            >
                              {rmsConfig.customer_bank_enabled && (
                                <FormControlLabel
                                  id="bank"
                                  value="transfer"
                                  control={
                                    <Radio
                                      color="default"
                                      size="small"
                                      onChange={() => {
                                        setSubmitDetails(true);
                                        setDetailsMethod("Bank");
                                      }}
                                    />
                                  }
                                  label={
                                    <p className="radio-label">Bank Account</p>
                                  }
                                />
                              )}
                              {rmsConfig.customer_upi_enabled && (
                                <FormControlLabel
                                  id="upi"
                                  value="upi"
                                  control={
                                    <Radio
                                      color="default"
                                      size="small"
                                      onChange={() => {
                                        setSubmitDetails(true);
                                        setDetailsMethod("UPI");
                                      }}
                                    />
                                  }
                                  label={<p className="radio-label">UPI</p>}
                                />
                              )}
                            </RadioGroup>
                          </FormControl>

                          <Drawer
                            classes={{ paper: "paper-drawer" }}
                            anchor="bottom"
                            open={submitDetails}
                            onClose={() => setSubmitDetails(false)}
                          >
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.08)",
                                  height: "80px",
                                  padding: "32px",
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: "1rem",
                                    fontWeight: "500",
                                    color: "#333",
                                  }}
                                >
                                  Enter {detailsMethod} Details
                                </p>
                                <p
                                  style={{ cursor: "pointer" }}
                                  onClick={() => setSubmitDetails(false)}
                                >
                                  {" "}
                                  <CloseIcon
                                    style={{
                                      width: "24px",
                                      height: "24px",
                                      stroke: "#333",
                                    }}
                                  />{" "}
                                </p>
                              </div>
                              {detailsMethod == "Bank" ? (
                                <BankForm
                                  handleSubmitBankDetails={
                                    handleSubmitBankDetails
                                  }
                                  submittingDetails={submittingDetails}
                                />
                              ) : (
                                <form
                                  onSubmit={handleSubmit(
                                    handleSubmitUPIDetails
                                  )}
                                  style={{ padding: "20px" }}
                                >
                                  <Controller
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                      required: true,
                                    }}
                                    name="upi"
                                    render={({ field }) => (
                                      <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="upi"
                                        label="UPI ID"
                                        error={Boolean(errors.upi)}
                                        helperText={
                                          errors.upi && "UPI code is required"
                                        }
                                        inputProps={{ type: "text" }}
                                        style={{ marginTop: "20px" }}
                                        {...field}
                                      />
                                    )}
                                  />
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={submittingDetails}
                                    style={{
                                      padding: "1rem",
                                      borderRadius:
                                        !merchantConfig.use_rounded_edge_buttons
                                          ? "0px"
                                          : "4px",
                                      margin: "40px auto",
                                      display: "block",
                                      cursor: "pointer",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {submittingDetails
                                      ? "Submitting..."
                                      : "Submit"}
                                  </Button>
                                </form>
                              )}
                            </div>
                          </Drawer>
                        </div>
                      )
                    )}
                    {detail.refund_method === "upi" && detail.vpa !== "" && (
                      <p>
                        UPI ID:{" "}
                        {detail.vpa.replace(/^(\d+)(\d{4})/, "*******$2")}
                      </p>
                    )}
                    {detail.refund_method === "coupon" && (
                      <p>
                        {detail.refund_id !== null && (
                          <span>Coupon ID: {detail.refund_id} and</span>
                        )}
                        {detail.refund_amount !== null && (
                          <span>
                            {" "}
                            Coupon discount: ₹ {detail.refund_amount}
                          </span>
                        )}
                      </p>
                    )}
                  </div>
                );
              })
            ) : (
              <div>
                {((returnItems && returnItems !== null) ||
                  (refundSource &&
                    refundSource !== null &&
                    refundSource !== "")) && (
                  <p
                    style={{
                      margin: "16px 0px 10px",
                      color: "#333",
                      textTransform: "capitalize",
                    }}
                    className="refund-preference"
                  >
                    {" "}
                    Refund preference selected&nbsp; - &nbsp;
                    <span style={{ fontWeight: "600" }}>
                      {refundSource === "bank_transfer" ? (
                        "Bank transfer"
                      ) : refundSource === "upi" ? (
                        "UPI"
                      ) : refundSource === "refund_to_source" ? (
                        <span style={{ textTransform: "initial" }}>
                          Your refund will be processed to your original mode of
                          payment
                        </span>
                      ) : refundSource == "coupon" ? (
                        rmsConfig.coupon_method_name
                      ) : refundSource !== null ? (
                        refundSource.replace("_", " ")
                      ) : (
                        refundSource ||
                        returnItems.customer_preferred_refund_method
                      )}
                    </span>
                  </p>
                )}
              </div>
            )}

            {refundSource != null &&
              (refundSource == "bank_transfer" || refundSource == "upi") &&
              rmsConfig.use_direct_transfer_for_refund && (
                <Button
                  startIcon={<EditIcon style={{ stroke: "#1d8dcc" }} />}
                  style={{ textTransform: "capitalize" }}
                  onClick={() => setOpenBankForm(true)}
                >
                  Edit Details
                </Button>
              )}

            {accHolderName &&
            accHolderName !== null &&
            accHolderName !== "" &&
            accountNumber &&
            accountNumber !== null &&
            accountNumber !== "" &&
            refundSource === "bank_transfer" ? (
              <p
                style={{ margin: "10px 0px", color: "#333", fontSize: "14px" }}
              >
                {" "}
                {accHolderName} &nbsp; - &nbsp;{" "}
                {accountNumber.replace(/^(\d+)(\d{4})/, "*******$2")}
              </p>
            ) : refundSource === "upi" &&
              upiValue &&
              upiValue !== null &&
              upiValue !== "" ? (
              <p style={{ fontSize: "14px" }}>UPI ID - {upiValue}</p>
            ) : (
              ""
            )}

            {paymentDetails && paymentDetails !== null && (
              <StyledPaymentDiv style={{ padding: "20px 0px 0px" }}>
                <p className="payment-title">Refund Details</p>
                <div className="subtotal-statement">
                  <p style={{ color: "#333333B2" }}>
                    Subtotal of Products (Refund)
                  </p>
                  <p
                    style={{
                      color: "#333333",
                      fontWeight: "500",
                      whiteSpace: "nowrap",
                      marginLeft: "1rem",
                    }}
                  >
                    ₹ {paymentDetails.refund_product_total}
                  </p>
                </div>

                {paymentDetails.refund_charges +
                  paymentDetails.preapplied_refund_charge >
                  0 && (
                  <div className="subtotal-statement">
                    <p style={{ color: "#333333B2" }}>
                      {paymentDetails.refund_charge_label &&
                      paymentDetails.refund_charge_label !== ""
                        ? paymentDetails.refund_charge_label
                        : "Handling Charges"}
                    </p>
                    <p
                      style={{
                        color: "#e74c3c",
                        fontWeight: "500",
                        marginLeft: "1rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      - ₹{" "}
                      {paymentDetails.refund_charges +
                        paymentDetails.preapplied_refund_charge}
                    </p>
                  </div>
                )}

                {paymentDetails.shipping_charges > 0 && (
                  <div className="subtotal-statement">
                    <p style={{ color: "#333333B2" }}>
                      {paymentDetails.shipping_charge_label &&
                      paymentDetails.shipping_charge_label !== ""
                        ? paymentDetails.shipping_charge_label
                        : "Shipping Charges"}
                    </p>
                    <p
                      style={{
                        color: "#e74c3c",
                        fontWeight: "500",
                        marginLeft: "1rem",
                        whiteSpace: "nowrap",
                      }}
                    >
                      - ₹ {paymentDetails.shipping_charges}
                    </p>
                  </div>
                )}

                {(paymentDetails.refund_mode_offer_amount > 0 ||
                  paymentDetails.item_level_refund_mode_offer_amount > 0) &&
                  paymentDetails.offer_refund_mode === refundSource && (
                    <div className="subtotal-statement">
                      <p style={{ color: "#333333B2" }}>Bonus</p>
                      <p
                        style={{
                          color: "#16AC37",
                          fontWeight: "500",
                          marginLeft: "1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        + ₹{" "}
                        {paymentDetails.offer_refund_mode_on === "item_level"
                          ? paymentDetails.total_refund_mode_offer_amount
                          : paymentDetails.refund_mode_offer_amount}
                      </p>
                    </div>
                  )}

                {paymentDetails.refund_amount >= 0 && (
                  <div
                    style={{
                      borderTop: "1px dashed #333333B2",
                      borderBottom: "1px dashed #333333B2",
                      padding: "1rem 0px",
                      margin: "1.25rem 0",
                    }}
                    className="subtotal-statement"
                  >
                    <Fragment>
                      <p style={{ color: "#333333B2" }}>
                        Total Amount to be refunded
                      </p>
                      <p
                        style={{
                          color: "#16AC37",
                          fontWeight: "600",
                          marginLeft: "1rem",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {calculatingLoader
                          ? "Calculating..."
                          : `₹ ${paymentDetails.refund_amount}`}
                      </p>
                    </Fragment>
                  </div>
                )}

                {refundSource !== paymentDetails.offer_refund_mode &&
                  paymentDetails.applicable_offer_amount > 0 && (
                    <p
                      style={{
                        background: "#FDF5F8",
                        color: "#f1416c",
                        padding: "5px 10px",
                        fontSize: "12px",
                        borderRadius: "4px",
                        width: "fit-content",
                      }}
                    >
                      Skip the Handling charges and get a{" "}
                      <span style={{ fontWeight: "600" }}>
                        {" "}
                        ₹ {paymentDetails?.applicable_offer_amount} bonus{" "}
                      </span>{" "}
                      by choosing{" "}
                      <span style={{ fontWeight: "600" }}>
                        {specialRefundModeLabel &&
                        specialRefundModeLabel !== "" &&
                        specialRefundMode !== null
                          ? specialRefundModeLabel
                          : paymentDetails?.offer_refund_mode !== null &&
                            paymentDetails?.offer_refund_mode.replace("_", " ")}
                      </span>{" "}
                    </p>
                  )}
              </StyledPaymentDiv>
            )}

            <div
              style={{
                marginTop: "10px",
                color: "rgba(51, 51, 51, 0.7)",
                listStyle: "inside",
              }}
              className="refund-note"
            >
              <p
                style={{
                  color: "#DD435D99",
                  marginRight: "5px",
                  fontWeight: "600",
                  textDecoration: "underline",
                  textUnderlineOffset: "5px",
                  marginBottom: "10px",
                  fontSize: "14px",
                }}
              >
                Note:
              </p>
              {rmsConfig.brand_refund_note &&
              rmsConfig.brand_refund_note !== null &&
              rmsConfig.brand_refund_note !== "" ? (
                parse(rmsConfig.brand_refund_note)
              ) : (
                <span>
                  {" "}
                  Refund amount may vary. Please read our terms & conditions to
                  know more.{" "}
                </span>
              )}
            </div>
          </div>
        )
      )}
    </div>
  );
};

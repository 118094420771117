import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  returnedProducts: [],
  refundJSON: {},
  refundSource: null,
};

const returnedProductsSlice = createSlice({
  name: "Returned products",
  initialState,
  reducers: {
    addReturnedProducts: (state, { payload }) => {
      state.returnedProducts = payload;
    },
    addRefundJSON: (state, { payload }) => {
      state.refundJSON = payload;
    },
    addRefundSource: (state, { payload }) => {
      state.refundSource = payload;
    },
  },
});

export const { addReturnedProducts, addRefundJSON, addRefundSource } =
  returnedProductsSlice.actions;

export const getReturnedProducts = (state) =>
  state.returnedReducer.returnedProducts;

export const getRefundJSON = (state) => state.returnedReducer.refundJSON;

export const getRefundSource = (state) => state.returnedReducer.refundSource;

export default returnedProductsSlice.reducer;

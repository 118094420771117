import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: {},
  selectedOrder: {},
  returnOrder: "",
  webSelected: false,
  swHeader: false,
  uniqueId: "",
};

const orderSlice = createSlice({
  name: "Orders",
  initialState,
  reducers: {
    addOrders: (state, { payload }) => {
      state.orders = payload;
    },
    updateSelectedOrder: (state, { payload }) => {
      state.selectedOrder = payload;
    },
    updateReturnOrder: (state, { payload }) => {
      state.returnOrder = payload;
    },
    updateWebSelected: (state, { payload }) => {
      state.webSelected = payload;
    },
    updateSWHeader: (state, { payload }) => {
      state.swHeader = payload;
    },
    updateUUID: (state, { payload }) => {
      state.uniqueId = payload;
    },
  },
});

export const {
  addOrders,
  updateSelectedOrder,
  updateReturnOrder,
  updateWebSelected,
  updateSWHeader,
  updateUUID,
} = orderSlice.actions;

export const getOrders = (state) => state.ordersReducer.orders;
export const getSelectedOrder = (state) => state.ordersReducer.selectedOrder;
export const getReturnOrder = (state) => state.ordersReducer.returnOrder;
export const getWebSelected = (state) => state.ordersReducer.webSelected;
export const getSWHeader = (state) => state.ordersReducer.swHeader;
export const getUUID = (state) => state.ordersReducer.uniqueId;

export default orderSlice.reducer;

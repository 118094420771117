import React from "react";
import { ReactComponent as BrokenLinkIcon } from "assets/brokenlink.svg";

function ErrorPage() {
  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        textAlign: "center",
        width: "100%",
      }}
    >
      <BrokenLinkIcon />
      <p style={{ color: "#333", fontSize: "40px", marginTop: "80px" }}>
        Uh Oh! :(
      </p>
      <p style={{ color: "#3339", fontSize: "20px", marginTop: "30px" }}>
        The page you are looking for has been moved, removed or never existed.
      </p>
      {sessionStorage.getItem("encipherencode") &&
        sessionStorage.getItem("encipherencode") !== null && (
          <a
            className="bg-primary"
            href={`https://${window.location.hostname}/?encipherencode=${sessionStorage.getItem(
              "encipherencode"
            )}`}
            style={{
              width: "300px",
              padding: "10px",
              fontSize: "18px",
              fontWeight: "600",
              background: "#3297D0",
              color: "#fff",
              textAlign: "center",
              margin: "30px auto",
              textDecoration: "none",
            }}
          >
            Reload
          </a>
      )}
    </div>
  );
}

export default ErrorPage;

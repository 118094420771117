import styled, { keyframes } from "styled-components";

const animation = keyframes`
    0%{
        opacity: 0;
        transform: translateY(25%);
    }
    100%{
        opacity: 1;
        transform: trabnslateY(0);
    }
`;

export const StyledProducts = styled.div`
  display: flex;
  border-radius: 4px;
  width: 100%;
  font-size: 1rem !important;

  .product-image {
    object-fit: contain;
    height: 200px;
    width: 240px;
    background: #fff;
    object-position: left;
  }

  .radio-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  .margin {
    margin: 10px 0px;
  }

  @media (max-width: 800px) {
    font-size: 14px !important;

    .top-banner {
      display: none;
    }

    .product-image {
      margin-top: 20px;
      padding-left: 15px;
      border-radius: 6px;
      width: 100px;
      height: 100px;
    }

    .radio-buttons {
      display: none;
    }

    .margin {
      margin: 10px 0px;
    }
  }
`;

export const StyledProductsWrapper = styled.div`
  padding: ${(props) =>
    props.location === "/products" && props.productConsent
      ? "170px 5.5% 0px 5.5%"
      : props.location === "/products" && !props.productConsent
      ? "100px 5.5% 0px 5.5%"
      : props.returnedProducts !== undefined &&
        props.returnedProducts.length > 0
      ? "20px 0px 0px 14%"
      : "25px 5.5% 0px 5.5%"};

  .products-skeleton {
    display: flex;
    width: ${(props) => (props.location === "/products" ? "45%" : "100%")};
    border: 1px solid rgba(17, 17, 17, 0.2);
    margin-left: ${(props) =>
      props.location === "/products" ? "40px" : "0px"};
    margin-top: 40px;

    .image-skeleton {
      width: 220px;
      height: 220px;
    }

    .text-skeleton {
      width: 250px;
      margin-top: 10px;
    }
  }
  .select {
    font-size: 1rem;
    color: #333;
    margin: 20px 0px;
    font-weight: 600;
  }

  .active {
    display: block;
  }

  @media (max-width: 800px) {
    padding: ${(props) =>
      props.location === "/products" && props.productConsent
        ? "170px 0px 0px"
        : props.location === "/products" && !props.productConsent
        ? "100px 0px 0px"
        : "30px 0px 0px"};
    .select,
    .help {
      padding: 0px 15px;
    }

    .select {
      font-size: 14px;
      margin: 0px;
      margin-bottom: 12px;
    }

    .products-skeleton {
      width: 90%;
      margin-left: 20px;
      margin-top: 20px;

      .image-skeleton {
        width: 140px;
        height: 140px;
      }

      .text-skeleton {
        width: 120px;
        margin-top: 0px;
      }
    }
  }
`;

export const StyledMobButtons = styled.div`
  @media (min-width: 800px) {
    display: none;
  }
`;

export const StyledReasonWrapper = styled.div`
  padding: 20px 0px;
  padding-top: 0px;
  border: 1px solid #11111133;
  overflow: auto;
  height: 85vh;
  background: #fff;
  border-radius: 10px 10px 0px 0px;

  :hover {
    overflow: auto;
  }

  .product_title {
    font-size: 14px;
  }

  .reason-form {
    font-size: 12px;
    padding: 10px 24px;
  }

  .reasons {
    display: ${(props) =>
      props.selectedReason && props.selectedReason !== null ? "none" : "block"};
  }

  .preference {
    margin: 10px 0px !important;
  }

  .different-skeleton {
    min-width: 150px;
    max-width: 400px;
  }

  .diff-prod-buttons {
    .exchange-button-web {
      display: none;
    }

    .exchange-button-mob {
      display: block;
      position: absolute;
      z-index: 25;
      bottom: 0;
      background: #fff;
      padding: 10px 16px;
      box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.12);
      overflow: hidden;
      width: 100%;
    }

    .back-button {
      display: none;
    }
  }

  .collections-section {
    padding: 0px 0px 10px 20px;
    overflow: auto;
    margin-right: 1rem;
    display: flex;

    ::-webkit-scrollbar {
      height: 2px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.primary_color};
    }
  }

  @media (min-width: 800px) {
    animation: ${animation} 1s linear;
    max-width: 500px;
    margin: auto;
    height: 75vh;
    .reason-form {
      font-size: 16px;
    }
    .preference {
      margin: 20px 0px;
    }
    .product_title {
      font-size: 16px;
    }
    .different-skeleton {
      .diif-image {
        width: 100%;
        margin: 10px 0px 5px;
        min-width: 200px;
      }
    }

    .diff-prod-buttons {
      .exchange-button-web {
        margin: 20px auto 20px;
        display: block;
      }

      .exchange-button-mob {
        display: none;
      }

      .back-button {
        display: block;
        margin: 20px auto;
      }
    }
  }
`;

export const StyledDifferentProduct = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;

  .product-image {
    width: 100%;
    height: initial;
    border-radius: 0px;
    margin: 0px;
  }

  .product-details {
    margin-left: 0px;
    padding: 1rem 1rem 60px;

    .product-title {
      color: #333;
      margin: 10px 0px;
      font-size: 14px;
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
    padding: 10px 20px;

    .product-image {
      width: 150px;
      height: 100%;
      max-height: 200px;
      border-radius: 4px;
      object-fit: contain;
    }

    .product-details {
      margin-left: 6px;
      padding: 0rem 1rem 0px;

      .product-title {
        font-size: 16px;
      }
    }
  }
`;

export const StyledVariantSelect = styled.div`
  padding: 0px 10px 60px;

  @media (min-width: 800px) {
    padding: 0px 20px 60px;
  }
`;

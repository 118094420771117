import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  phone: "",
  email: "",
  firstName: null,
  lastName: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    addPhone: (state, { payload }) => {
      state.phone = payload;
    },
    addEmail: (state, { payload }) => {
      state.email = payload;
    },
    addFirstName: (state, { payload }) => {
      state.firstName = payload;
    },
    addLastName: (state, {payload}) => {
      state.lastName = payload;
    }
  },
});

export const { addPhone, addEmail, addFirstName, addLastName } = userSlice.actions;

export const getPhone = (state) => state.userReducer.phone;

export const getEmail = (state) => state.userReducer.email;

export const getFirstName = (state) => state.userReducer.firstName;

export const getLastName = (state) => state.userReducer.lastName;

export default userSlice.reducer;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rmsConfig: {},
  merchantConfig: {},
};

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    addRmsConfig: (state, { payload }) => {
      state.rmsConfig = payload;
    },
    addMerchantConfig: (state, { payload }) => {
      // Only update if the payload is different from the current state
      if (JSON.stringify(state.merchantConfig) !== JSON.stringify(payload)) {
        state.merchantConfig = payload;
      }
    },
  },
});

export const { addRmsConfig, addMerchantConfig } = configSlice.actions;

// RMS Config
export const getRmsConfig = (state) => state.configReducer.rmsConfig;

// Merchant Config
export const getMerchantConfig = (state) => state.configReducer.merchantConfig;

export default configSlice.reducer;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { persistor, store } from "features/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import {
//   useLocation,
//   useNavigationType,
//   createRoutesFromChildren,
//   matchRoutes,
// } from "react-router-dom";

// Sentry.init({
//   dsn: process.env.REACT_APP_DSN,
//   integrations: [
//     new BrowserTracing(
//       React.useEffect,
//       useLocation,
//       useNavigationType,
//       createRoutesFromChildren,
//       matchRoutes
//     ),
//   ],
//   tunnel: `${process.env.REACT_APP_API_URL}/sentry_tunnel/bugs`,
//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Router>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled from "styled-components";

export const StyledOrderList = styled.div`
  padding: 100px 0% 100px 5.5%;
  display: flex;

  .order-skeleton {
    margin-top: 30px;
    width: 45%;
  }

  .customer-name {
    color: var(--color-primary);
    font-weight: 400;
    font-size: 1.25rem;
    margin: 12px 0px;
  }

  .order-wrapper {
    width: 50%;
    float: left;
    .return-container {
      width: 90%;
      .return-box {
        width: 48%;
        padding: 24px;
        margin: 10px 0px;
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid rgba(17,17,17,0.2);
      }
    }
  }

  .return-for {
    color: #333333b2;
    font-size: 1rem;
    margin: 20px 0px;
  }

  .return-policy {
    margin: 15px 0px;
    color: #333333;
    font-size: 14px;
  }

  .selected {
    border: 1px solid #111111 !important;
    background: #f5f5f5;
  }

  @media (max-width: 800px) {
    padding: 100px 0px 0px;
    justify-content: center;

    .order-skeleton {
      margin-top: 10px;
      width: 90%;
    }

    .customer-name {
      font-size: 16px;
      margin: 20px 0px 6px;
      padding: 0 15px;
    }

    .order-wrapper {
      width: 100%;
      display: ${(props) =>
        props.selectedOrder !== undefined &&
        props.selectedOrder.length > 0
          ? "none"
          : "block"};
      .return-container {
        width: 100%;
        .return-box {
          width: 90%;
          border: none;
          margin: 10px 15px 0px !important;
          background: #fff;
        }
      }
    }

    .return-for {
      font-size: 12px;
      padding: 0 15px;
    }

    .return-policy {
      font-size: 12px;
      padding: 0 15px;
    }

    .more-orders,
    .help,
    .retry {
      padding: 0 15px;
    }
  }
`;

export const StyledOrderBox = styled.div`
  padding: 15px;
  border-radius: 4px;
  border: 1px solid  rgba(17, 17, 17, 0.2);
  margin: 20px 0px;
  width: 90%;
  background: ${(props) => (props.eligible ? "#fff" : "rgba(0,0,0,0.1)")};
  cursor: ${(props) => (props.eligible ? "pointer" : "not-allowed")};
  position: relative;

  :hover {
    border: ${(props) =>
      props.eligible
        ? `1px solid #111111`
        : "1px solid rgba(17, 17, 17, 0.2)"};
    background: ${(props) => (props.eligible ? "#f5f5f5" : "rgba(0,0,0,0.1)")};
  }

  .order-number {
    font-size: 1rem;
    color: #333333;
    text-decoration: underline;
  }

  .total-price {
    font-size: 1rem;
    color: #333333b2;
    margin-left: 4px;
  }

  @media (max-width: 800px) {
    margin: 20px 15px;
    border: none;

    .order-number, .total-price, .fulfilment-status {
      font-size: 0.75rem;
    }
  }
`;

export const StyledAddress = styled.div`
  .address-skeleton {
    margin-top: 220px;
    width: 100%;
    min-width: 300px;
  }

  @media (min-width: 800px) {
    border-left: 1px dashed rgba(17, 17, 17, 0.2);
    padding: 0px 40px;
    width: 50%;
    float: right;
  }
`;

import React from "react";
import { useSelector } from "react-redux";
import { getRmsConfig } from "features/config/configSlice";

function Quantity({ quantityInc, setQuantityInc, originalQuantity }) {
  const rmsConfig = useSelector(getRmsConfig);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: rmsConfig.supports_return_partial_qtty
          ? "40px 0px"
          : "20px 0px",
      }}
    >
      <p
        style={{
          color: "#474747",
          fontWeight: "500",
          fontSize: "14px",
          marginRight: rmsConfig.supports_return_partial_qtty ? "20px" : "6px",
        }}
      >
        Quantity to be returned:{" "}
      </p>
      {rmsConfig.supports_return_partial_qtty ? (
        <div style={{ display: "flex" }}>
          <button
            disabled={
              !rmsConfig.supports_return_partial_qtty || quantityInc === 1
            }
            style={{
              padding: "10px 16px",
              border: "1px solid #000",
              background: "#fff",
              width: "50px",
              height: "50px",
              fontSize: "16px",
              cursor: "pointer",
              color:
                !rmsConfig.supports_return_partial_qtty || quantityInc === 1
                  ? "#33333333"
                  : "#333333",
            }}
            onClick={() => {
              if (quantityInc > 0) {
                setQuantityInc(quantityInc - 1);
              }
            }}
          >
            &#8212;
          </button>
          <button
            style={{
              padding: "10px 16px",
              border: "1px solid #000",
              borderLeft: "none",
              borderRight: "none",
              width: "50px",
              height: "50px",
              background: "#fff",
            }}
          >
            {quantityInc}
          </button>
          <button
            disabled={
              !rmsConfig.supports_return_partial_qtty ||
              quantityInc === originalQuantity
            }
            style={{
              padding: "10px 16px",
              border: "1px solid #000",
              background: "#fff",
              width: "50px",
              height: "50px",
              fontSize: "24px",
              cursor: "pointer",
              color:
                !rmsConfig.supports_return_partial_qtty ||
                quantityInc === originalQuantity
                  ? "#33333333"
                  : "#333333",
            }}
            onClick={() => {
              setQuantityInc(quantityInc + 1);           
            }}
          >
            +
          </button>
        </div>
      ) : (
        <p>{quantityInc}</p>
      )}
    </div>
  );
}

export default Quantity;

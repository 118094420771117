import React, { Fragment } from "react";
import { addPreviews, addUploads } from "features/product/productSlice";
import { useDispatch } from "react-redux";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { toast } from "react-toastify";

function ImageUpload(props) {
  const { uploads, setUploads, preview, setPreview, selectedReason } = props;

  const dispatch = useDispatch();

  toast.configure();
  const handleSubmit = (e) => {
    let tempFiles = [];
    const files = e.target.files;
    const filesToUpload = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = file.type;

      // Check if the file type is HEIC
      if (fileType === "image/heic" || fileType === "image/heif") {
        toast.error(
          `File ${file.name} is a HEIC file and will not be uploaded.`,
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 6000,
            hideProgressBar: false,
            closeOnClick: true,
          }
        );
      } else {
        // Add the non-HEIC file to the array
        filesToUpload.push(file);
      }
    }
    if (uploads !== undefined) {
      setUploads([...uploads, ...filesToUpload]);
      dispatch(addUploads([...uploads, ...filesToUpload]));
      tempFiles = [...uploads, ...filesToUpload];
    } else {
      setUploads(filesToUpload);
      dispatch(addUploads(filesToUpload));
      tempFiles = filesToUpload;
    }
    if (filesToUpload) {
      let tempArray = [];
      for (let i = 0; i < tempFiles.length; i++) {
        let reader = new FileReader();
        reader.onloadend = function (e) {
          tempArray.push(e.target.result);
          if (preview !== undefined) {
            setPreview([...preview, ...tempArray]);
            dispatch(addPreviews([...preview, ...tempArray]));
          } else {
            setPreview([...tempArray]);
            dispatch(addPreviews([...tempArray]));
          }
        };
        if (filesToUpload[i] !== undefined) {
          reader.readAsDataURL(e.target.files[i]);
        }
      }
    }
  };

  const removeImage = (i) => {
    var deletedUpload = uploads.splice(i, 1);

    if (uploads.length > 0) {
      setUploads(uploads.filter((a) => a !== deletedUpload));
      dispatch(addUploads(uploads.filter((a) => a !== deletedUpload)));
    } else {
      setUploads([]);
      dispatch(addUploads([]));
    }
    var deletedPreview = preview.splice(i, 1);
    if (preview.length > 0) {
      setPreview(preview.filter((a) => a !== deletedPreview));
      dispatch(addPreviews(preview.filter((a) => a !== deletedPreview)));
    } else {
      setPreview([]);
      dispatch(addPreviews([]));
    }
  };

  return (
    <div style={{ margin: "40px 0px 20px" }}>
      <p style={{ color: "#333", fontWeight: "600", fontSize: "14px" }}>
        Please upload{" "}
        {selectedReason.min_number_of_photos > 0
          ? selectedReason.min_number_of_photos
          : 1}{" "}
        image{selectedReason.min_number_of_photos > 1 && "s"}{" "}
        {selectedReason.min_number_of_videos > 0 &&
          selectedReason.min_number_of_photos > 0 &&
          "and"}{" "}
        {selectedReason.min_number_of_videos > 0 &&
          `${selectedReason.min_number_of_videos} video`}
        {selectedReason.min_number_of_videos > 1 && "s"} for verification
      </p>
      {uploads && uploads.length > 0 && preview ? (
        <div
          style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
        >
          {preview &&
            preview.map((prev, i) => {
              return (
                <div
                  key={i}
                  style={{
                    border: "1px solid #33333366",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minWidth: "150px",
                    width: "100%",
                    marginRight: "20px",
                    marginTop: "20px",
                    borderRadius: "4px",
                    padding: "10px",
                    maxWidth: "200px",
                  }}
                >
                  <img
                    src={prev}
                    alt="preview"
                    style={{ width: "40px", height: "40px" }}
                  />
                  {uploads[i] !== undefined &&
                    uploads[i].size !== undefined && (
                      <p>
                        {uploads[i].size < 1000000
                          ? Math.round((uploads[i].size / 1000) * 100) / 100 +
                            " KB"
                          : Math.round((uploads[i].size / 1000000) * 100) /
                              100 +
                            " MB"}
                      </p>
                    )}
                  <p
                    style={{ cursor: "pointer", marginTop: "4px" }}
                    onClick={() => removeImage(i)}
                  >
                    <CloseIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        stroke: "#333333",
                      }}
                    />
                  </p>
                </div>
              );
            })}
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            accept={
              selectedReason.min_number_of_videos > 0 &&
              selectedReason.min_number_of_photos > 0
                ? "video/mp4, image/png, image/jpg, image/jpeg"
                : selectedReason.min_number_of_videos > 0
                ? "video/mp4"
                : "image/png, image/jpg, image/jpeg"
            }
            multiple
            onChange={(e) => handleSubmit(e)}
          />
          <label htmlFor="file">
            <p
              style={{
                color: "#333",
                textDecoration: "underline",
                cursor: "pointer",
                fontSize: "12px",
                marginTop: "10px",
                fontWeight: "600",
              }}
            >
              Add more files
            </p>
          </label>
        </div>
      ) : (
        <Fragment>
          <input
            type="file"
            id="file"
            style={{ display: "none" }}
            accept={
              selectedReason.min_number_of_videos > 0 &&
              selectedReason.min_number_of_photos > 0
                ? "video/mp4, image/png, image/jpg, image/jpeg"
                : selectedReason.min_number_of_videos > 0
                ? "video/mp4"
                : "image/png, image/jpg, image/jpeg"
            }
            multiple
            onChange={(e) => handleSubmit(e)}
          />
          <label htmlFor="file">
            <p
              style={{
                color: "#333",
                padding: "20px",
                textAlign: "center",
                border: "1px dashed #33333380",
                margin: "20px 0px",
                cursor: "pointer",
              }}
            >
              Upload Files
            </p>
          </label>
        </Fragment>
      )}
    </div>
  );
}

export default ImageUpload;

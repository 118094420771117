import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
};

const successProducts = createSlice({
  name: "Success Products",
  initialState,
  reducers: {
    addReturnRequestId: (state, { payload }) => {
      state.products = payload;
    },
  },
});

export const { addReturnRequestId } = successProducts.actions;

export const getReturnRequestId = (state) =>
  state.successProductsReducer.products;

export default successProducts.reducer;
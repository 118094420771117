import React, { useRef, useState } from "react";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { Avatar, Divider } from "@mui/material";
import {
  createComment,
  getComments,
  uploadCommentMedia,
  validateMedia,
} from "utils/api";
import { useEffect } from "react";
import { format, parseISO } from "date-fns";
import { useSelector } from "react-redux";
import { getMerchantConfig, getRmsConfig } from "features/config/configSlice";
import { ReactComponent as SendIcon } from "assets/send.svg";
import { ReactComponent as AttachIcon } from "assets/paperclip.svg";
import ModalImage from "react-modal-image";
import { BeatLoader } from "react-spinners";
import { ReactComponent as CloseIcon } from "assets/close.svg";

function Comment(props) {
  const [openComments, setOpenComments] = useState(false);
  const [comments, setComments] = useState([]);
  const merchantConfig = useSelector(getMerchantConfig);
  const { fullName, return_request_id, blurTime } = props;
  const [commentInput, setCommentInput] = useState("");
  const [uploads, setUploads] = useState(null);
  const uplaod_media = uploads !== null ? true : false;
  const rmsConfig = useSelector(getRmsConfig);
  const [file, setFile] = useState(null);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, [comments, openComments]);

  useEffect(() => {
    function commentsCall() {
      getComments(return_request_id).then((resp) => {
        setComments(resp.data);
      });
    }
    commentsCall();
    const interval = setInterval(() => {
      if (blurTime > 0) {
        commentsCall();
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function commentCreation() {
    setUploadLoading(true);
    if (uploads !== null) {
      validateMedia(file, merchantConfig.merchant_id, true)
        .then((resp) => {
          createComment(return_request_id, commentInput, uplaod_media).then(
            (resp) => {
              if (uploads !== null) {
                uploadCommentMedia(
                  return_request_id,
                  resp.data.comment_id,
                  file
                ).then((resp) => {
                  setComments([...comments, resp.data]);
                  setCommentInput("");
                  setUploads(null);
                  setUploadLoading(false);
                });
              } else {
                setComments([...comments, resp.data]);
                setCommentInput("");
                setUploads(null);
                setUploadLoading(false);
              }
            }
          );
        })
        .catch((err) => {
          setUploadError(err.response.data.error);
        });
    } else {
      createComment(return_request_id, commentInput, uplaod_media).then(
        (resp) => {
          if (uploads !== null) {
            uploadCommentMedia(
              return_request_id,
              resp.data.comment_id,
              file
            ).then((resp) => {
              setComments([...comments, resp.data]);
              setCommentInput("");
              setUploads(null);
              setUploadLoading(false);
            });
          } else {
            setComments([...comments, resp.data]);
            setCommentInput("");
            setUploads(null);
            setUploadLoading(false);
          }
        }
      );
    }
  }

  const handleUploadFile = (e) => {
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    setFile(formData);
    setUploads(e.target.files[0]);
  };

  return (
    <div
      style={{
        boxShadow: "0px -2px 4px rgba(29, 141, 204, 0.25)",
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <div
        className="comments-header"
        onClick={() => setOpenComments(!openComments)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "10px",
            width: "100%",
          }}
        >
          <ChatBubbleOutlineOutlinedIcon />
          <p style={{ marginLeft: "1rem" }}>Leave a message</p>
        </div>
        {openComments ? (
          <KeyboardArrowDownOutlinedIcon />
        ) : (
          <KeyboardArrowUpOutlinedIcon />
        )}
      </div>

      <Divider style={{ borderColor: "grey" }} />

      {openComments && (
        <div style={{ background: "#fff", height: "400px" }}>
          <div
            style={{
              height: "350px",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            {comments.length > 0 ? comments.map((comment, i) => {
              return (
                <div className={`commented-${comment.commented_by}`} key={i}>
                  <div
                    style={{
                      display: "flex",
                      width:
                        comment.commented_by === "merchant" ? "100%" : "auto",
                    }}
                  >
                    <div className={`comment-${comment.commented_by}`}>
                      <p>{comment.comment}</p>
                      {comment.media.map((media,i) => {
                        return (
                          <div
                            style={{
                              width: "100px",
                              display: "block",
                              margin: "1rem auto 0px",
                            }}
                            key={i}
                          >
                            {media.media_type.indexOf("image") !== -1 && (
                              <ModalImage
                                alt="Preview"
                                small={`${rmsConfig.s3_url}${media.file}`}
                                large={`${rmsConfig.s3_url}${media.file}`}
                              />
                            )}
                            {media.media_type.indexOf("video") !== -1 && (
                              <video src={media.file} />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {comment.commented_by === "customer" && (
                      <Avatar
                        sx={{
                          bgcolor: merchantConfig.primary_theme_color,
                          color: merchantConfig.secondary_theme_color,
                          height: "32px",
                          width: "32px",
                          fontSize: "14px",
                          marginTop: "1rem",
                        }}
                      >
                        {fullName.split(" ")[0][0]}
                        {fullName.split(" ")[1][0]}
                      </Avatar>
                    )}
                  </div>
                  <p
                    style={{
                      margin:
                        comment.commented_by === "merchant"
                          ? "10px"
                          : "10px 50px",
                      textTransform: "lowercase",
                    }}
                  >
                    {format(
                      parseISO(comment.created_at),
                      "dd MMM yyyy, hh:mm aa"
                    )}
                  </p>
                </div>
              );
            }) : <p style={{color: '#33333366', margin: '150px auto', fontSize: '20px'}}>We will contact u soon :)</p>
          }
            <div ref={containerRef} />
          </div>
          <div>
            {uploads !== null && uploadLoading ? (
              <BeatLoader
                style={{
                  width: "14px",
                  height: "14px",
                  color: merchantConfig.primary_theme_color,
                }}
              />
            ) : uploads !== null && !uploadLoading ? (
              <div
                style={{
                  background: `${merchantConfig.primary_theme_color}`,
                  color: merchantConfig.secondary_theme_color,
                  padding: "5px 10px",
                  zIndex: "99",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p>1 file selected</p>{" "}
                <CloseIcon
                  style={{
                    stroke: merchantConfig.secondary_theme_color,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setUploads(null);
                    setFile(null);
                  }}
                />{" "}
              </div>
            ) : (
              ""
            )}
            {uploadError !== null && (
              <div
                style={{
                  color: "red",
                  background: "#ff000033",
                  padding: "5px 10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {" "}
                <p>{uploadError}</p>{" "}
                <CloseIcon
                  style={{ stroke: "#333" }}
                  onClick={() => setUploadError(null)}
                />{" "}
              </div>
            )}
            <div
              style={{
                border: "1px solid #3333331A",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="file"
                id="file"
                style={{ display: "none" }}
                accept="video/*, image/*"
                onChange={(e) => handleUploadFile(e)}
              />
              <label htmlFor="file">
                <AttachIcon
                  style={{ margin: "10px 0px 0px 10px", cursor: "pointer" }}
                />
              </label>
              <input
                value={commentInput}
                onChange={(e) => setCommentInput(e.target.value)}
                type="text"
                placeholder="Leave a message"
                style={{
                  border: "none",
                  padding: "16px 10px",
                  width: "100%",
                  outline: merchantConfig.primary_theme_color,
                  display: "block",
                }}
              />
              <SendIcon
                style={{ margin: "0px 20px", cursor: "pointer" }}
                onClick={() => commentCreation()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Comment;

import React, { useState, useEffect } from "react";
import { Button, Grid } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate, useSearchParams } from "react-router-dom";
import Countdown from "react-countdown";
import { resendOTP, verifyOTP } from "utils/api";
import { BeatLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { getMerchantConfig } from "features/config/configSlice";

function Otp({ inputValue, setConfirmOtp, response, setResponse }) {
  const navigate = useNavigate();
  const [otpNumber1, setOtpNumber1] = useState("");
  const [otpNumber2, setOtpNumber2] = useState("");
  const [otpNumber3, setOtpNumber3] = useState("");
  const [otpNumber4, setOtpNumber4] = useState("");
  const [otpNumber5, setOtpNumber5] = useState("");
  const [otpNumber6, setOtpNumber6] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resend, setResend] = useState(false);
  const [errorType, setErrorType] = useState(null);
  const [otpResent, setOtpResent] = useState(false);
  const [currentDate, setCurrentDate] = useState(Date.now());
  const merchantConfig = useSelector(getMerchantConfig);
  const [params, setParams] = useSearchParams();
  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  const handleLength = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    // Check if they hit the max character length
    if (value.length >= maxLength) {
      // Check if it's not the last input field
      if (parseInt(fieldIndex, 10) < 6) {
        // Get the next input field
        const nextSibling = document.querySelector(
          `input[name=otp-${parseInt(fieldIndex, 10) + 1}]`
        );

        // If found, focus the next field
        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }
  };

  useEffect(() => {
    if (otpNumber6.length === 1) {
      setIsLoading(true);
      handleProceed();
    }
  }, [otpNumber6]);

  const handleProceed = () => {
    let otp =
      String(otpNumber1) +
      String(otpNumber2) +
      String(otpNumber3) +
      String(otpNumber4) +
      String(otpNumber5) +
      String(otpNumber6);

    if (response && response !== null && otp.length === 6) {
      verifyOTP(response.phonenumber, response.unique_id, otp, response.email)
        .then((resp) => {
          setIsLoading(false);
          if (resp.data.success) {
            let url = "/orders";
            if (encipherencode && encipherencode !== null) {
              url += `?encipherencode=${encipherencode}`;
            }
            navigate(url);
            const iframe = document.getElementById("sessionIframe");
            iframe.contentWindow.postMessage(
              {
                phone: response.phonenumber,
                email: response.email,
                topic: "PRAGMA",
              },
              "*"
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          setOtpError(true);
          setErrorType(err.response?.data?.error || "An error occurred");
        });
    } else {
      setIsLoading(false);
    }
  };

  const renderer = ({ minutes, seconds, completed }) => {
    if (minutes.toString().length == 1) {
      minutes = "0" + minutes.toString();
    }
    if (seconds.toString().length == 1) {
      seconds = "0" + seconds.toString();
    }
    if (completed) {
      setResend(true);
    } else {
      return minutes + " : " + seconds;
    }
  };

  const handleResend = () => {
    if (response && response !== null) {
      resendOTP(
        merchantConfig.merchant_id,
        response.unique_id,
        response.phonenumber,
        response.email
      )
        .then((resp) => {
          setOtpResent(true);
          setResponse(resp.data);
          setErrorType("OTP Resent Successfully");
        })
        .catch((err) => {
          setOtpError(true);
          setErrorType(err.response?.data?.error || "An error occurred");
        });
    }
  };

  const handleBack = (e) => {
    const { value, maxLength, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");

    if (e.keyCode == 8) {
      const prevSibling = document.querySelector(
        `input[name=otp-${parseInt(fieldIndex, 10) - 1}]`
      );

      if (value.length < maxLength) {
        if (prevSibling !== null) {
          prevSibling.focus();
        }
      }
    }
  };

  const handlePaste = (e) => {
    var paste = (e.clipboardData || window.clipboardData).getData("text");
    if (paste) {
      if (parseInt(paste)) {
        paste = paste.toString().split("");
        setOtpNumber1(paste[0]);
        setOtpNumber2(paste[1]);
        setOtpNumber3(paste[2]);
        setOtpNumber4(paste[3]);
        setOtpNumber5(paste[4]);
        setOtpNumber6(paste[5]);
        document.querySelector(`input[name=otp-6]`).focus();
      }
    }
  };

  return (
    <Grid
      className="login-section"
      item
      xs={12}
      md={6}
      style={{
        background: "#fff",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "20px",
      }}
    >
      <div style={{ maxWidth: "450px", textAlign: "center" }}>
        <p className="return-title" style={{ marginBottom: "25px" }}>
          Place a Refund/Exchange Request
        </p>
        <p style={{ color: "rgba(51, 51, 51, 0.7)", lineHeight: "1.5rem" }}>
          We have sent an OTP to{" "}
          <span style={{ fontWeight: "600" }}>{inputValue.toLowerCase()}</span>.{" "}
          <span
            style={{
              color: "#1d8dcc",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setConfirmOtp(false)}
          >
            Change
          </span>
        </p>
        <p
          style={{
            marginTop: "40px",
            textAlign: "left",
            color: "rgba(51, 51, 51, 0.7)",
          }}
        >
          Enter OTP here
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div style={{ marginTop: "10px" }}>
            <input
              autoFocus
              type="number"
              className="otp-input"
              value={otpNumber1}
              maxLength={1}
              name="otp-1"
              style={{
                marginLeft: "0px",
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
                borderRadius: merchantConfig.use_rounded_edge_buttons
                  ? "4px"
                  : "0px",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber1(e.target.value);
                }
              }}
              onPaste={(e) => handlePaste(e)}
              onKeyDown={(e) => handleBack(e)}
            />
            <input
              type="number"
              className="otp-input"
              value={otpNumber2}
              maxLength={1}
              name="otp-2"
              style={{
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
                borderRadius: merchantConfig.use_rounded_edge_buttons
                  ? "4px"
                  : "0px",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber2(e.target.value);
                }
              }}
              onKeyDown={(e) => handleBack(e)}
            />
            <input
              type="number"
              className="otp-input"
              value={otpNumber3}
              maxLength={1}
              name="otp-3"
              style={{
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
                borderRadius: merchantConfig.use_rounded_edge_buttons
                  ? "4px"
                  : "0px",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber3(e.target.value);
                }
              }}
              onKeyDown={(e) => handleBack(e)}
            />
            <input
              type="number"
              className="otp-input"
              value={otpNumber4}
              maxLength={1}
              name="otp-4"
              style={{
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
                borderRadius: merchantConfig.use_rounded_edge_buttons
                  ? "4px"
                  : "0px",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber4(e.target.value);
                }
              }}
              onKeyDown={(e) => handleBack(e)}
            />
            <input
              type="number"
              className="otp-input"
              value={otpNumber5}
              maxLength={1}
              name="otp-5"
              style={{
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber5(e.target.value);
                }
              }}
              onKeyDown={(e) => handleBack(e)}
            />
            <input
              type="number"
              className="otp-input"
              value={otpNumber6}
              maxLength={1}
              name="otp-6"
              style={{
                border:
                  otpError && !otpResent
                    ? "1px solid rgba(221, 67, 93, 1)"
                    : "1px solid rgba(217, 217, 217, 1)",
              }}
              onChange={(e) => {
                if (e.target.value.length < 2) {
                  handleLength(e);
                  setOtpNumber6(e.target.value);
                }
              }}
              onKeyDown={(e) => handleBack(e)}
            />
          </div>
          {(otpError || otpResent) && (
            <p
              style={{
                color: otpResent
                  ? "rgba(51, 51, 51, 0.7)"
                  : "rgba(221, 67, 93, 1)",
                marginTop: "10px",
                textAlign: "left",
              }}
            >
              {errorType}
            </p>
          )}
          <Button
            type="submit"
            variant="contained"
            className="login-action"
            endIcon={
              isLoading ? (
                <BeatLoader color={merchantConfig.secondary_theme_color} />
              ) : (
                <ArrowForwardIcon />
              )
            }
            color="primary"
            style={{
              width: "100%",
              borderRadius: !merchantConfig.use_rounded_edge_buttons
                ? "0px"
                : "4px",
              padding: "10px",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "30px",
            }}
          >
            {isLoading ? "" : "Proceed"}
          </Button>
        </form>
        <Button
          variant="text"
          color="primary"
          disabled={!resend}
          onClick={() => handleResend()}
          style={{
            margin: "30px auto",
            textAlign: "center",
            fontSize: "16px",
            textTransform: "capitalize",
          }}
        >
          <span style={{ color: "#000" }}> Resend OTP &nbsp; </span>{" "}
          {!resend && (
            <Countdown
              key={1}
              date={currentDate + 60000}
              renderer={renderer}
              onComplete={() => setResend(true)}
            />
          )}
        </Button>
        {merchantConfig.whatsapp_help &&
          merchantConfig.whatsapp_help !== null && (
            <a
              href={merchantConfig.whatsapp_help}
              target="_blank"
              rel="noreferrer"
              style={{
                textAlign: "center",
                display: "block",
                margin: "40px auto",
                color: "#000000B2",
                fontSize: "12px",
              }}
            >
              Need Help?
            </a>
          )}
      </div>
    </Grid>
  );
}

export default Otp;

import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { configAPI } from "utils/api";
import {
    addMerchantConfig,
    addRmsConfig,
  } from "features/config/configSlice";
import WebFont from "webfontloader";

function MaintenancePage() {
  const [params, setParams] = useSearchParams();
  const dispatch = useDispatch();

  const cname = window.location.hostname;

  const encipherencode =
    sessionStorage.getItem("encipherencode") || params.get("encipherencode");

  const navigate = useNavigate();

  const reload = () => {
    configAPI(
      cname !== "localhost" &&
        encipherencode !== undefined &&
        encipherencode !== null
        ? encipherencode
        : cname === "localhost"
        ? "gAAAAABlYOUldB0g5Yh6vD2Wq5jrFUWPifS8_DhQAuGA_nKPlmw2cL6z9YPEEYcGiI_eDfvbS9h7b2RUPXognGgjvzNfop7WHQ=="
        : null,
      cname !== "localhost" &&
        (encipherencode === undefined || encipherencode === null)
        ? cname
        : null
    )
      .then((resp) => {
        if (resp.data.merchant_config.google_font_family !== null) {
          WebFont.load({
            google: {
              families: [resp.data.merchant_config.google_font_family],
            },
          });
        }
        dispatch(addRmsConfig(resp.data.rms_config));
        dispatch(addMerchantConfig(resp.data.merchant_config));
        if (resp.data.under_maintenance) {
          navigate("/maintenance");
        }else{
            navigate("/");
        }
      })
      .catch((err) => {
        // console.log(err);
        navigate("/error");
      })
      .finally(() => {
        try {
          sessionStorage.setItem(
            "encipherencode",
            cname !== "localhost"
              ? encipherencode
              : "gAAAAABlYOUldB0g5Yh6vD2Wq5jrFUWPifS8_DhQAuGA_nKPlmw2cL6z9YPEEYcGiI_eDfvbS9h7b2RUPXognGgjvzNfop7WHQ=="
          );
        } catch {}
      });
  };

  return (
    <div
      style={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        textAlign: "center",
        width: "100%",
      }}
    >
      <img src="/maintenance.png" style={{ width: "25%", margin: "0 auto" }} />
      <p style={{ fontWeight: "700", fontSize: "36px", marginTop: "0px" }}>
        Under Maintenance
      </p>
      <p style={{ color: "#4d4d4d", marginTop: "30px", fontSize: "20px" }}>
        The page you are looking for is under maintanence
      </p>
      <p style={{ color: "#4d4d4d", marginTop: "5px", fontSize: "20px" }}>
        Please try again after sometime
      </p>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{
          width: "300px",
          borderRadius: "0px",
          padding: "10px",
          fontSize: "18px",
          fontWeight: "600",
          display: "block",
          margin: "30px auto"
        }}
        onClick={() => reload()}
      >
        Try again
      </Button>
    </div>
  );
}

export default MaintenancePage;

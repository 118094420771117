import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addressDetails: {
    pincode: "",
    state: "",
    city: "",
    addressLine1: "",
    addressLine2: "",
    firstName: "",
    lastName: "",
    country: "",
  },
  shipment: "",
  serviceability: false,
  serviceableCheck: false,
  tempServiceability: false
};

const addressSlice = createSlice({
  name: "Address Details",
  initialState,
  reducers: {
    addAddress: (state, { payload }) => {
      state.addressDetails.pincode = payload.pincode;
      state.addressDetails.state = payload.state;
      state.addressDetails.city = payload.city;
      state.addressDetails.addressLine1 = payload.addressLine1;
      state.addressDetails.addressLine2 = payload.addressLine2;
      state.addressDetails.firstName = payload.firstName;
      state.addressDetails.lastName = payload.lastName;
      state.addressDetails.country = payload.country;
    },
    addShipmentMethod: (state, { payload }) => {
      state.shipment = payload;
    },
    updateServiceability: (state, { payload }) => {
      state.serviceability = payload;
    },
    updateServiceCheck: (state, { payload }) => {
      state.serviceableCheck = payload;
    },
    updateTempServiceability: (state, { payload }) => {
      state.tempServiceability = payload;
    },
  },
});

export const { addAddress, addShipmentMethod, updateServiceability, updateServiceCheck, updateTempServiceability } =
  addressSlice.actions;

export const getAddress = (state) => state.addressReducer.addressDetails;

export const getShipmentMethod = (state) => state.addressReducer.shipment;

export const getServiceability = (state) => state.addressReducer.serviceability;

export const getServiceabileCheck = (state) => state.addressReducer.serviceableCheck;

export const getTempServiceability = (state) => state.addressReducer.tempServiceability;

export default addressSlice.reducer;

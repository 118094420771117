import { combineReducers, configureStore } from "@reduxjs/toolkit";
import configReducer from "./config/configSlice";
import userReducer from "./user/userSlice";
import ordersReducer from "./orders/orderSlice";
import productReducer from "./product/productSlice";
import returnedReducer from "./returnedProducts/returnedProductSlice";
import addressReducer from "./address/addresSlice";
import successProductsReducer from "./successProducts/successProductsSlice";
import storage from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  configReducer,
  userReducer,
  ordersReducer,
  productReducer,
  returnedReducer,
  addressReducer,
  successProductsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

import React, { useState, useEffect } from "react";
import { Routes, Route, useSearchParams, useNavigate } from "react-router-dom";
import Orders from "components/Orders";
import Login from "components/Login";
import ProductsDisplay from "components/Products";
import ReturnCart from "components/ReturnCart";
import Success from "components/Success";
import Tracking from "components/Tracking";
import ErrorPage from "components/Error";
import WebFont from "webfontloader";
import { createTheme, ThemeProvider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addMerchantConfig,
  addRmsConfig,
  getMerchantConfig,
} from "features/config/configSlice";
import { configAPI } from "utils/api";
import Home from "components/Home";
import Footer from "components/common/Footer";
import MaintenancePage from "components/Error/Maintenance";
// import styled from "styled-components";

function App() {
  const [loginLoader, setLoginLoader] = useState(true);
  const dispatch = useDispatch();

  const [params, setParams] = useSearchParams();

  const cname = window.location.hostname;

  const paramsEncipherenCode = params.get("encipherencode");

  const encipherencode =
    sessionStorage.getItem("encipherencode") || paramsEncipherenCode;

  const navigate = useNavigate();

  // const StyledBanner = styled.div`
  //   background: #f5c6ce;
  //   color: #dd435d;
  //   text-align: center;
  //   padding: 25px;
  //   font-size: 18px;
  //   line-height: 1.2;
  //   position: fixed;
  //   top: 0;
  //   width: 100%;
  //   z-index: 99;

  //   @media (max-width: 800px) {
  //     font-size: 14px;
  //   }
  // `;

  const merchantConfig = useSelector(getMerchantConfig);

  useEffect(() => {
    const isLocalhost = cname === "localhost";
    const defaultCode =
      "gAAAAABmhl81_N038e-lfPa1zKoaIhpAyHCuCb17QzbP4W5NBHy9noZnuBcs8QxE0L28hiFclPIkTjMiZIQ3T7yAwSOE0-iwtA==";
    const currentCode =
      paramsEncipherenCode ?? (isLocalhost ? defaultCode : encipherencode);

    const fetchConfig = (code, name) => {
      configAPI(code, name)
        .then((resp) => {
          if (resp.data.merchant_config.google_font_family) {
            WebFont.load({
              google: {
                families: [resp.data.merchant_config.google_font_family],
              },
            });
          }
          dispatch(addRmsConfig(resp.data.rms_config));
          dispatch(addMerchantConfig(resp.data.merchant_config));
          if (resp.data.under_maintenance) {
            navigate("/maintenance");
          }
        })
        .catch(() => {
          navigate("/error");
        })
        .finally(() => {
          try {
            sessionStorage.setItem(
              "encipherencode",
              isLocalhost ? defaultCode : currentCode
            );
          } catch {}
        });
    };

    if (paramsEncipherenCode !== encipherencode) {
      fetchConfig(currentCode, isLocalhost ? null : cname);
    } else if (
      Object.keys(merchantConfig).length === 0 ||
      !merchantConfig ||
      isLocalhost
    ) {
      fetchConfig(currentCode, isLocalhost ? null : cname);
    }
  }, []);

  useEffect(() => {
    const head = document.head || document.getElementsByTagName("head")[0];

    const setOrUpdateElement = (tag, attributes) => {
      let element = document.querySelector(
        `${tag}[name~='${attributes.name}']`
      );
      if (!element) {
        element = document.createElement(tag);
        Object.keys(attributes).forEach((key) => {
          element[key] = attributes[key];
        });
        head.appendChild(element);
      } else {
        Object.keys(attributes).forEach((key) => {
          element[key] = attributes[key];
        });
      }
    };

    const scripts = [
      {
        src: merchantConfig.ga_head_script,
        async: true,
      },
      {
        innerHTML: merchantConfig.ga_body_script,
        type: "text/javascript",
      },
    ];

    scripts.forEach((scriptAttrs) => {
      if (scriptAttrs.src || scriptAttrs.innerHTML) {
        const script = document.createElement("script");
        Object.keys(scriptAttrs).forEach((key) => {
          script[key] = scriptAttrs[key];
        });
        head.appendChild(script);
      }
    });

    if (merchantConfig && Object.keys(merchantConfig).length > 0) {
      setOrUpdateElement("link", { rel: "icon", href: merchantConfig.favicon });
      setOrUpdateElement("title", {
        innerText: `${merchantConfig.shop_name} - Returns and Exchanges Portal`,
      });
      setOrUpdateElement("meta", {
        name: "description",
        content:
          "Hassle-free returns for your orders, brought to you by Pragma",
      });
      setOrUpdateElement("meta", {
        name: "og:title",
        content: `${merchantConfig.shop_name} - Returns and Exchanges Portal`,
      });
      setOrUpdateElement("meta", {
        name: "og:description",
        content:
          "Hassle-free returns for your orders, brought to you by Pragma",
      });
      setOrUpdateElement("meta", {
        name: "og:image",
        content: merchantConfig.brand_logo,
      });
    }
  }, [merchantConfig]);

  const theme = createTheme({
    typography: {
      fontFamily: [
        merchantConfig.google_font_family !== null
          ? merchantConfig.google_font_family
          : "Poppins",
        "sans-serif",
      ].join(","),
    },
    palette: {
      primary: {
        main:
          merchantConfig.primary_theme_color !== undefined
            ? merchantConfig.primary_theme_color
            : "#111",
        contrastText:
          merchantConfig.secondary_theme_color !== undefined
            ? merchantConfig.secondary_theme_color
            : "#fff",
      },
    },
  });

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        {/* <StyledBanner>
          Returns system is facing some issues. Please try again in a few hours.
        </StyledBanner> */}
        <iframe
          id="sessionIframe"
          style={{ display: "none" }}
          src="https://orders.logisy.tech/session"
        ></iframe>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <Login
                loginLoader={loginLoader}
                setLoginLoader={setLoginLoader}
              />
            }
          />
          <Route
            path="/returns"
            element={
              <Login
                loginLoader={loginLoader}
                setLoginLoader={setLoginLoader}
              />
            }
          />
          <Route exact path="/maintenance" element={<MaintenancePage />} />
          <Route
            path="/orders"
            element={<Orders setLoginLoader={setLoginLoader} />}
          />
          <Route path="/products" element={<ProductsDisplay />} />
          <Route path="/return-cart" element={<ReturnCart />} />
          <Route path="/success" element={<Success />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/error" element={<ErrorPage />} />
        </Routes>
        {merchantConfig && merchantConfig.is_pragma_branding_visible && (
          <Footer />
        )}
      </ThemeProvider>
    </div>
  );
}

export default App;
